import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function signUp(firstName, lastName, email, phone, password, setUser, setAuthUser) {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const authUser = userCredential.user;
        const displayName = capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);
        await updateProfile(auth.currentUser, { displayName });

        const user = await createNewUser(firstName, lastName, email, phone, setUser, authUser);
        setAuthUser(authUser);
        console.log(user, " is created");
        notification("Success", `Signed up ${email} successfully`, "success");
        return true;
    } catch (err) {
        console.log(err);
        let message = err.message;

        if (err.code === "auth/email-already-in-use") {
            message = "This email is already in use. Please use another email";
        }
        notification("Error", message, "warning");
        return null;
    }
}

const createNewUser = async (firstName, lastName, email, phone, setUser, authUser) => {
    try {
        const userRef = doc(db, "users", email);
        const displayName = capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);

        const userData = {
            email,
            phone,
            firstName: capitalizeFirstLetter(firstName),
            lastName: capitalizeFirstLetter(lastName),
            displayName: displayName,
            id: email,
            uid: authUser.uid
        };
        await setDoc(userRef, userData, { merge: true });
        setUser(userData);
        console.log("Firebase user created", firstName);
        return userData;
    } catch (err) {
        notification("Error", err.message, "danger");
        console.log(err);
    }
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
