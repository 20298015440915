import { storage } from "../firebase/firebase-utils";
import getFileName from "../utils-functions/getFileName";
import { ref, getDownloadURL } from "firebase/storage";

export default async function getThumbnail(file) {
    const fileName = getFileName(file.name) + "_1080x1080.jpeg";
    const fullPath = file.folderRef + "/thumbnails/" + fileName;
    const storageRef = ref(storage, fullPath);

    try {
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        console.log(error);
        return null
    }
}
