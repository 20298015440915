import { Container } from "@mui/system";
import { GridContainer, GridDivider, GridFlexBox, IconC, Title } from "../themes/themes";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import loadPVs from "../functions/loadPVs";
import { PaymentVouchersContext } from "../context/PaymentVouchersContext";
import PaymentVoucherComponent from "../components/PaymentVoucherComponent";
import { Checkbox } from "../components/Checkbox";
import SelectComponent2 from "../components/SelectComponent2";
import EmptySpace from "../components/EmptySpace";
import deletePVs from "../functions/deletePVs";
import { ConfirmationContext } from "../context-utils/ConfirmationContext";

export default function PaymentVouchersPage() {
    const { paymentVouchers, setPaymentVouchers } = useContext(PaymentVouchersContext);
    const { user } = useContext(AuthContext);
    const { openConfirmation } = useContext(ConfirmationContext);

    const [select, setSelect] = useState([]);

    useEffect(() => {
        if (!paymentVouchers) getData();
    }, []);

    const getData = async () => {
        const paymentVouchers = await loadPVs(user);
        console.log(paymentVouchers);
        setPaymentVouchers(paymentVouchers);
    };

    const handleDelete = async (select) => {
        const response = await openConfirmation("Delete Payment Vouchers", "Click OK to continue");
        if (response) {
            const list = select.map((id) => paymentVouchers.find((pv) => pv.id === id));
            await deletePVs(list, paymentVouchers, setPaymentVouchers);
            setSelect([]);
        }
    };

    const checkUser = () => {
        if (user.role === "Super Admin" || user.role === "Admin") return true;
        return false;
    };

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox gap={1}>
                    <Title>Payment Vouchers List</Title>
                    <IconC big onClick={getData}>
                        refresh
                    </IconC>
                </GridFlexBox>
                <GridDivider />

                {paymentVouchers &&
                    paymentVouchers.map((pv) => (
                        <React.Fragment key={pv.id}>
                            {checkUser() && <Checkbox select={select} id={pv.id} setSelect={setSelect} />}
                            <PaymentVoucherComponent voucher={pv} />
                        </React.Fragment>
                    ))}
                <SelectComponent2
                    select={select}
                    data={paymentVouchers}
                    setSelect={setSelect}
                    handleDelete={handleDelete}
                />
                <EmptySpace />
            </GridContainer>
        </Container>
    );
}
