import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function editAction(action, collectionId, docId) {
    try {
        const docRef = doc(db, collectionId, docId, "actions", action.id);
        await updateDoc(docRef, action);
        console.log("Action updated");
    } catch (error) {
        console.log(error);
    }
}
