export default function convertDate(date) {
    let newDate = date;
    if (typeof date !== "object") {
        if (typeof date == "number") {
            newDate = new Date(date * 1000); //change Unix time
        }
        if (typeof date == "string") {
            newDate = new Date(date);
        }
    } else {
        newDate = new Date(date.seconds * 1000);
    }

    if (date instanceof Date) newDate = date;
    var d = new Date(newDate);

    return d;
}
