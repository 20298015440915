import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function deleteSale(id) {
    try {
        const docRef = doc(db, "sales", id);
        await deleteDoc(docRef);
        console.log("Sale " + id + " deleted");
    } catch (err) {
        console.log(err);
    }
}
