import React from "react";
import { GridDivider, GridFlexBox, Name } from "../themes/themes";
import convertDate from "../utils-functions/convertDate";
import formatDate from "../utils-functions/formatDate";
import formatNumber from "../utils-functions/formatNumber";

export default function PaymentVoucherComponent({ voucher }) {
    return (
        <React.Fragment>
            <GridFlexBox fs gap={1} m0 w="50%">
                <Name>Date: </Name>
                <Name fs14>{voucher && formatDate(convertDate(voucher.date))}</Name>
            </GridFlexBox>
            <GridFlexBox w="50%" fe gap={1}>
                <Name fs12>{voucher && voucher.pv}</Name>
            </GridFlexBox>
            <GridFlexBox fs gap={1}>
                <Name>Pay to</Name>
                <Name bold>{voucher && voucher.displayName}</Name>
            </GridFlexBox>
            {voucher &&
                voucher.sales.map((sale, j) => (
                    <React.Fragment key={j}>
                        <GridFlexBox fs key={j} w={"20%"}>
                            <Name fs12>{formatDate(convertDate(sale.date))}</Name>
                        </GridFlexBox>
                        <GridFlexBox fs w="35%" gap={1}>
                            <Name fs12>{sale.propertyName}</Name>
                            <Name fs12>{sale.unitNumber}</Name>
                        </GridFlexBox>
                        <GridFlexBox fs w="25%">
                            <Name fs12>{sale.clientName}</Name>
                        </GridFlexBox>
                        <GridFlexBox fe w={"20%"}>
                            <Name fs12>{formatNumber(sale.commission)}</Name>
                        </GridFlexBox>
                    </React.Fragment>
                ))}
            <GridFlexBox w="70%" fe>
                <Name>Total:</Name>
            </GridFlexBox>
            <GridFlexBox fe w="30%">
                <Name bold fs12>
                    {voucher && formatNumber(getTotal(voucher.sales))}
                </Name>
            </GridFlexBox>
            <GridDivider />
        </React.Fragment>
    );
}

function getTotal(sales) {
    let total = 0;
    sales.forEach((sale) => {
        total = total + sale.commission;
    });
    return total;
}
