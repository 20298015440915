import { AccountCircle, PriceCheck } from "@mui/icons-material";
import { Button, Divider, styled, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Box } from "@mui/system";
import formatNumber from "../utils-functions/formatNumber";
import { GridFlexBox, Name } from "../themes/themes";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const TextBox = styled(Typography)({
    marginRight: 1,
    marginTop: 1,
    textAlign: "left"
});

const FaceIcon = styled(AccountCircle)({
    color: blue[500],
    marginRight: 1,
    marginTop: 1
});

const CommissionList = ({ sale }) => {
    const { user } = useContext(AuthContext);
    const [display, setDisplay] = React.useState(false);
    const [displayButton, setDisplayButton] = React.useState(false);

    useEffect(() => {
        if (!sale) return;
        console.log(sale);
        let displayButton = false;
        for (let member of sale.members) {
            if (member.id === user.id) {
                displayButton = true;
                break;
            }
        }
        if (user.role === "Super Admin" || user.role === "Admin") displayButton = true;
        setDisplayButton(displayButton);
    }, [sale]);

    const displayMember = (member) => {
        if (user.id === member.id) return true;
        if (user.role === "Super Admin" || user.role === "Admin") return true;
        return false;
    };

    const displayProfit = (user) => {
        if (user.role === "Super Admin" || user.role === "Admin") return true;
        return false;
    };

    const displayCommission = (member, sale) => {
        if (member.netCommission) {
            return formatNumber(parseFloat(member.netCommission));
        } else {
            return formatNumber(parseFloat(member.percentage / 100) * sale.profit);
        }
    };

    return (
        <>
            {displayButton && (
                <GridFlexBox fs>
                    <Button size="small" variant="outlined" onClick={() => setDisplay(!display)}>
                        Commission
                    </Button>
                </GridFlexBox>
            )}

            {display && (
                <>
                    {displayProfit(user) && (
                        <React.Fragment>
                            <GridFlexBox w="10%" fs>
                                <PriceCheck fontSize="small" />
                            </GridFlexBox>
                            <GridFlexBox w="20%" fs>
                                <Name>Profit</Name>
                            </GridFlexBox>
                            <GridFlexBox w="30%" fe>
                                <TextBox variant="body2" style={{ textAlign: "right", borderBottom: "solid 1px" }}>
                                    {sale && sale.profit && formatNumber(sale.profit)}
                                </TextBox>
                            </GridFlexBox>
                            <GridFlexBox w="40%"></GridFlexBox>
                        </React.Fragment>
                    )}
                    {sale &&
                        sale.members &&
                        sale.members.map(
                            (member, i) =>
                                displayMember(member) && (
                                    <React.Fragment key={i}>
                                        <GridFlexBox w="10%" fs>
                                            <FaceIcon fontSize="small" />
                                        </GridFlexBox>
                                        <GridFlexBox w="20%" fs>
                                            <TextBox variant="body2">{member.displayName}</TextBox>
                                        </GridFlexBox>
                                        <GridFlexBox w="30%" fe>
                                            <TextBox variant="body2" style={{ textAlign: "right" }}>
                                                {displayCommission(member, sale)}
                                            </TextBox>
                                        </GridFlexBox>
                                        <GridFlexBox w="40%" fe></GridFlexBox>
                                    </React.Fragment>
                                )
                        )}
                    <Box>
                        <Divider style={{ marginTop: 15 }} />
                    </Box>
                </>
            )}
        </>
    );
};

export default CommissionList;
