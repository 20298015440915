import {
    AppBar,
    Box,
    Dialog,
    IconButton,
    Slide,
    Toolbar,
    Typography
} from "@mui/material";
import { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullDialog({
    open,
    handleClose,
    title = "Dialog",
    Component
}) {
    return (
        <Box>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar
                    position={"fixed"}
                    style={{ backgroundColor: "#4a656e" }}
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleClose()}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="inherit">{title}</Typography>
                    </Toolbar>
                </AppBar>
                <Box pt={9}>{Component}</Box>
            </Dialog>
        </Box>
    );
}
