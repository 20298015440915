import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function addSale(state, members, user) {
    const involve = members.map((member) => member.id);
    try {
        const docRef = await addDoc(collection(db, "sales"), {
            ...state,
            date: new Date(state.date),
            members: members,
            dateCreated: new Date(),
            createdBy: { displayName: user.displayName, id: user.id },
            involve: involve
        });
        console.log("Added sale into Database: ", docRef.id);
        notification("Success", "Sale saved into Database", "success");
    } catch (err) {
        console.log(err);
    }
}
