import { Button } from "@mui/material";
import { Container } from "@mui/system";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommissionList from "../components/CommissionList";
import SaleDetails from "../components/SaleDetails";
import { SalesContext } from "../context/SalesContext";
import { GridContainer, GridDivider, GridFlexBox, Loading } from "../themes/themes";
import ActionPanel from "../components/ActionPanel";
import { AuthContext } from "../context/AuthContext";
import addAction from "../functions/addAction";
import ActionsList from "../components/ActionsList";
import loadSaleRT from "../functions/loadSaleRT";
import loadActions from "../functions/loadActions";
import deleteAction from "../functions/deleteAction";
import { ConfirmationContext } from "../context-utils/ConfirmationContext";
import editAction from "../functions/editAction";

export default function SaleCardPage() {
    const { user } = useContext(AuthContext);
    const { sales } = useContext(SalesContext);
    const { openConfirmation } = useContext(ConfirmationContext);

    const [sale, setSale] = useState();
    const [actions, setActions] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { saleId } = useParams();

    useEffect(() => {
        const loadData = async () => {
            if (sales) {
                const sale = sales.find((doc) => doc.id === saleId);
                setSale(sale);
            } else {
                loadSaleRT(saleId, user, setSale, setLoading);
            }
        };
        loadData();
    }, [sales]);

    useEffect(() => {
        const getData = () => {
            const unsubscribe = loadActions("sales", saleId, setActions, setLoading);
            return unsubscribe;
        };
        return getData();
    }, [saleId]);

    const editSale = () => {
        navigate(`/add-sale-page/${sale.id}`);
    };

    const handleAddAction = async (action) => {
        const newAction = { date: new Date(), name: user.displayName, email: user.email, comment: action };
        await addAction(newAction, "sales", saleId);
    };

    const handleDeleteAction = async (action) => {
        const response = await openConfirmation("Delete action", "Press OK to confirm delete");
        if (response) {
            await deleteAction(action, actions, "sales", saleId);
        }
    };

    const handleEditAction = async (action) => {
        await editAction(action, "sales", saleId);
    };

    const handleAddFile = async (files, response) => {
        const newAction = {
            date: new Date(),
            name: user.displayName,
            email: user.email,
            files: files,
            comment: response
        };
        await addAction(newAction, "sales", saleId);
    };

    let title = "";
    if (sale) {
        title = sale.propertyName + " " + sale.unitNumber + " (" + sale.clientName + ")";
    }

    return (
        <Container maxWidth={"sm"}>
            <GridContainer>
                <Loading loading={loading} />
                {sale && <SaleDetails handleOpen={null} sale={sale} />}
                <CommissionList sale={sale} />
                <GridDivider />
                <ActionPanel handleAddAction={handleAddAction} handleAddFile={handleAddFile} title={title} />
                {actions && (
                    <>
                        <ActionsList
                            actions={actions}
                            handleDelete={handleDeleteAction}
                            handleEditAction={handleEditAction}
                        />
                        <GridDivider />
                    </>
                )}
                <GridFlexBox fs>
                    <Button
                        size="small"
                        color="warning"
                        variant="contained"
                        style={{ margin: "3px" }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        Back
                    </Button>
                    <Button size="small" color="info" variant="contained" onClick={editSale} style={{ margin: "3px" }}>
                        Edit
                    </Button>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
        </Container>
    );
}
