import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

// eslint-disable-next-line
export default async function loadUsers(user) {
    try {
        const usersRef = collection(db, "users");
        const usersSnap = await getDocs(usersRef);

        const users = mapSnapshot(usersSnap);
        return users;
    } catch (err) {
        console.log(err);
    }
}
