import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useContext, useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import Menu from "./components/Menu";
import { AuthContext } from "./context/AuthContext";
import LandingPage from "./pages/LandingPage";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import routes from "./routes/routes";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline } from "@mui/material";

function App() {
    const { user } = useContext(AuthContext);
    const [theme, colorMode] = useMode();

    const displayRoutes = useMemo(() => {
        if (user) {
            return routes();
        } else {
            return (
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route exact path="/sign-up" element={<SignUp />} />
                    <Route exact path="/sign-in" element={<SignIn />} />
                    <Route path="*" element={<LandingPage />} />
                    <Route path="forgot-password-page" element={<ForgotPasswordPage />} />
                </Routes>
            );
        }
    }, [user]);

    return (
        <div>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Menu user={user} />
                    {displayRoutes}
                </ThemeProvider>
                <br />
            </ColorModeContext.Provider>
        </div>
    );
}

export default App;
