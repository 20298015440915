import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function fillUpInvolve() {
    try {
        const collectionRef = collection(db, "sales");
        const docsSnap = await getDocs(collectionRef);
        const sales = docsSnap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
        }));
        console.log(sales);

        sales.forEach(async (sale) => {
            const involve = sale.members.map((member) => member.id);
            const newSale = { ...sale, involve: involve };

            const saleRef = doc(db, "sales", sale.id);
            await setDoc(saleRef, newSale, { merge: true });
            console.log(sale.id, " updated");
        });

        return sales;
    } catch (err) {
        console.log(err);
    }
}
