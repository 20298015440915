import { ArrowDownward } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import { addDoc, collection, doc, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import React, { useContext, useEffect, useMemo } from "react";
import ActionPanel from "../../components/ActionPanel";
import ActionsList from "../../components/ActionsList";
import { ConfirmationContext } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import addAction from "../../functions/addAction";
import deleteAction from "../../functions/deleteAction";
import loadActions from "../../functions/loadActions";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../../themes/themes";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { notification } from "../../utils-functions/notification";

export default function ProducstsPage({ addItem, part }) {
    const [loading, setLoading] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [actions, setActions] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [currentProduct, setCurrentProduct] = React.useState(null);
    const { user } = useContext(AuthContext);
    const { openConfirmation } = useContext(ConfirmationContext);

    useEffect(() => {
        if (!user || !user.companyId) return;
        const collectionRef = collection(db, "products");
        const q = query(collectionRef, where("companyId", "==", user.companyId), orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setLoading(true);
            const products = mapSnapshot(querySnapshot);
            setProducts(products);
            setLoading(false);
        });
        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (!currentProduct) return;
        const unsubscribe = loadActions("products", currentProduct.id, setActions, setLoading);
        return unsubscribe;
    }, [currentProduct]);

    const handleChangeSearch = (e) => {
        setSearch(e.target.value);
    };

    const handleAddProduct = async () => {
        if (!search) return;
        const newProduct = { date: new Date(), name: search, createdBy: user.id, companyId: user.companyId };
        const collectionRef = collection(db, "products");
        const response = await addDoc(collectionRef, newProduct);
        console.log("newProduct", newProduct, response.id);
        setSearch("");
        setCurrentProduct({ ...newProduct, id: response.id });
    };

    function handleClickProduct(product) {
        setCurrentProduct(product);
    }

    const handleEditProduct = (value, field) => {
        setCurrentProduct((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = async () => {
        const docRef = doc(db, "products", currentProduct.id);
        await setDoc(docRef, currentProduct, { merge: true });
        notification("Success", "Product saved successfully", "success");
    };

    const handleAddAction = async (action) => {
        const newAction = { date: new Date(), name: user.displayName, email: user.email, comment: action };
        await addAction(newAction, "products", currentProduct.id);
    };

    const handleAddFile = async (files, response) => {
        const newAction = {
            date: new Date(),
            name: user.displayName,
            email: user.email,
            files: files,
            comment: response
        };
        await addAction(newAction, "products", currentProduct.id);
    };

    const handleDeleteAction = async (action) => {
        const response = await openConfirmation("Delete action", "Press OK to confirm delete");
        if (response) {
            await deleteAction(action, actions, "products", currentProduct.id);
        }
    };

    const displayProducts = useMemo(() => {
        if (arrayIsEmpty(products)) return [];
        if (!search) return products;
        return products.filter((product) => {
            if (product.name) {
                if (product.name.toString().toLowerCase().includes(search.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            }
        });
    }, [products, search]);

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Products</Title>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
            <Grid container display="flex" spacing={2} sx={{ marginTop: "1px" }}>
                <Grid item xs={12} md={4}>
                    <Grid container display="flex" spacing={1}>
                        <GridFlexBox>
                            <TextField
                                size="small"
                                fullWidth
                                sx={{ marginBottom: "8px" }}
                                placeholder="Search products / Add new product ..."
                                value={search}
                                onChange={handleChangeSearch}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Box display={"flex"}>
                                                <IconButton disabled={!search} onClick={handleAddProduct}>
                                                    <AddIcon />
                                                </IconButton>
                                                <IconButton disabled={!search} onClick={() => setSearch("")}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </Box>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </GridFlexBox>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper
                                sx={{
                                    height: ["30vh", "65vh"],
                                    width: "100%",
                                    overflowY: "scroll",
                                    paddingTop: "8px",
                                    paddingBottom: "8px"
                                }}
                            >
                                <List>
                                    {!arrayIsEmpty(displayProducts) &&
                                        displayProducts.map((product, i) => (
                                            <ListItemButton
                                                key={product.id}
                                                onClick={() => handleClickProduct(product)}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Icon>{"category"}</Icon>
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontWeight:
                                                                    currentProduct?.id === product.id ? "bold" : "none"
                                                            }}
                                                        >
                                                            {product["name"]}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        ))}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container display="flex" spacing={1}>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper sx={{ height: ["40vh", "70vh"], width: "100%", padding: "16px" }}>
                                {currentProduct && (
                                    <>
                                        <Grid container display="flex" spacing={2}>
                                            <TextFieldCustom
                                                label="Name"
                                                field="name"
                                                value={currentProduct?.name}
                                                onChange={handleEditProduct}
                                            />
                                            <TextFieldCustom
                                                label="Description"
                                                field="description"
                                                value={currentProduct?.description || ""}
                                                onChange={handleEditProduct}
                                            />
                                            <TextFieldCustom
                                                label="Cost"
                                                type="number"
                                                field="cost"
                                                value={currentProduct?.cost || ""}
                                                onChange={handleEditProduct}
                                            />
                                            <TextFieldCustom
                                                label="Price"
                                                type="number"
                                                field="price"
                                                value={currentProduct?.price || ""}
                                                onChange={handleEditProduct}
                                            />
                                            <TextFieldCustom
                                                label="Unit"
                                                field="unit"
                                                value={currentProduct?.unit || ""}
                                                onChange={handleEditProduct}
                                            />
                                            <Grid item display="flex" xs={6}>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleSave}
                                                    sx={{ marginTop: "8px" }}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                            {addItem && (
                                                <Grid item display="flex" justifyContent={"flex-end"} xs={6}>
                                                    <Button
                                                        variant="contained"
                                                        color="warning"
                                                        onClick={() => addItem(currentProduct, part)}
                                                        sx={{ marginTop: "8px" }}
                                                        startIcon={<ArrowDownward />}
                                                    >
                                                        Add to Quotation
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container display="flex" spacing={1}>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <GridContainer>
                                <ActionPanel handleAddAction={handleAddAction} handleAddFile={handleAddFile} />
                            </GridContainer>
                        </Grid>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper
                                sx={{ height: ["50vh", "70vh"], width: "100%", padding: "16px", overflowY: "scroll" }}
                            >
                                <GridContainer>
                                    <ActionsList
                                        actions={actions}
                                        handleDelete={handleDeleteAction}
                                        handleEditAction={() => {}}
                                    />
                                </GridContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const TextFieldCustom = ({ label = "", value = "", onChange = () => {}, type = "text", field = "" }) => {
    const [value_, setValue_] = React.useState("");

    useEffect(() => {
        if (type === "number") {
            setValue_(formatNumberWithSeparator(value));
        } else {
            setValue_(value);
        }
    }, [value]);

    const handleChange = (e) => {
        if (type === "number") {
            const result = formatNumberWithSeparator(e.target.value);
            setValue_(result);
            let parsedNumber = parseFloat(result.replace(/,/g, ""));
            onChange(parsedNumber, field);
        } else {
            setValue_(e.target.value);
            onChange(e.target.value, field);
        }
    };

    return (
        <Grid item display="flex" xs={12}>
            <TextField size="small" fullWidth label={label} value={value_} onChange={handleChange} />
        </Grid>
    );
};

function formatNumberWithSeparator(number) {
    const value = number.toString();
    const parts = value.split(".");
    // remove non numeric [0-9] characters
    parts[0] = parts[0].replace(/\D/g, "");
    // add commas back
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts[1]) {
        // remove non numeric and more than 2 characters for decimals
        parts[1] = parts[1].substring(0, 2).replace(/\D/g, "");
    }
    const result = parts.join(".");
    return result;
}
