import React, { useContext, useEffect, useState } from "react";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, Name } from "../themes/themes";
import { Container } from "@mui/system";
import formatDate from "../utils-functions/formatDate";
import convertDate from "../utils-functions/convertDate";
import formatNumber from "../utils-functions/formatNumber";
import DateSelect from "./DateSelect";
import removeSpace from "../utils-functions/removeSpace";
import { ConfirmationContext } from "../context-utils/ConfirmationContext";

const DATE = formatDate(new Date());

export default function GeneratePaymentVouchers({ sales = [], handleGenerate }) {
    const { openConfirmation } = useContext(ConfirmationContext);
    const [members, setMembers] = useState([]);
    const [date, setDate] = useState(DATE);

    useEffect(() => {
        sales.sort((a, b) => a.date - b.date);
        getMembers(sales, setMembers);
    }, [sales]);

    const handleClick = async () => {
        let newMembers = [...members];
        newMembers.forEach((member, i) => {
            const pv = "PV-" + date + "-" + removeSpace(member.displayName);
            const amount = getTotal(member.sales).toFixed(2);
            newMembers[i] = { ...member, date: new Date(date), pv, amount };
        });
        const response = await openConfirmation("Generate Payment Vouchers", "Press OK to proceed.");
        if (response) {
            handleGenerate(newMembers);
        }
    };

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridDivider />
                {members.map((member, i) => (
                    <React.Fragment key={i}>
                        <GridFlexBox fs>
                            <Name bold>{member.displayName}</Name>
                        </GridFlexBox>
                        {member.sales.map((sale, j) => (
                            <React.Fragment key={j}>
                                <GridFlexBox fs key={j} w={"20%"}>
                                    <Name fs12>{formatDate(convertDate(sale.date))}</Name>
                                </GridFlexBox>
                                <GridFlexBox fs w="35%" gap={1}>
                                    <Name fs12>{sale.propertyName}</Name>
                                    <Name fs12>{sale.unitNumber}</Name>
                                </GridFlexBox>
                                <GridFlexBox fs w="25%">
                                    <Name fs12>{sale.clientName}</Name>
                                </GridFlexBox>
                                <GridFlexBox fe w={"20%"}>
                                    <Name fs12>{formatNumber(sale.commission)}</Name>
                                </GridFlexBox>
                            </React.Fragment>
                        ))}
                        <GridFlexBox w="70%" fe>
                            <Name>Total:</Name>
                        </GridFlexBox>
                        <GridFlexBox fe w="30%">
                            <Name bold fs12>
                                {formatNumber(getTotal(member.sales))}
                            </Name>
                        </GridFlexBox>
                        <GridDivider />
                    </React.Fragment>
                ))}
                <GridFlexBox fs w="70%" p2>
                    <DateSelect date={date} setDate={setDate} />
                </GridFlexBox>
                <GridFlexBox fe w="30%" p2>
                    <ButtonC onClick={handleClick}>Generate</ButtonC>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
        </Container>
    );
}

function getMembers(sales, setMembers) {
    const members = [];
    sales.forEach((sale) => {
        let totalCommission = 0;
        const { date, id, profit, propertyName, saleAmount, unitNumber, clientName } = sale;
        sale.members.forEach((member) => {
            const f = members.findIndex((m) => m.id === member.id);
            const saleData = {
                date,
                id,
                profit,
                propertyName,
                saleAmount,
                unitNumber,
                clientName,
                percentage: member.percentage,
                role: member.role,
                commission:
                    parseFloat(member.netCommission) || (parseFloat(profit) * parseFloat(member.percentage)) / 100
            };
            totalCommission = totalCommission + saleData.commission;
            if (f === -1) {
                members.push({
                    id: member.id,
                    displayName: member.displayName,
                    sales: [saleData]
                });
            } else {
                members[f].sales.push(saleData);
            }
        });

        // Calculate company commission
        const f = members.findIndex((m) => m.id === "company");
        const companySaleData = {
            date,
            id: "company",
            profit,
            propertyName,
            saleAmount,
            unitNumber,
            clientName,
            role: "company",
            commission: parseFloat(profit) - parseFloat(totalCommission)
        };
        if (f === -1) {
            members.push({
                id: "company",
                displayName: "company",
                sales: [companySaleData]
            });
        } else {
            members[f].sales.push(companySaleData);
        }
    });

    setMembers(members);
}

function getTotal(sales) {
    let total = 0;
    sales.forEach((sale) => {
        total = total + sale.commission;
    });
    return total;
}
