import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";
import uuid from "react-uuid";

export default async function addPaymentVouchers(vouchers, sales, user) {
    try {
        const batchId = uuid();
        const pvIds = await Promise.all(
            vouchers.map(async (voucher) => {
                const pv = {
                    ...voucher,
                    involve: [voucher.id],
                    email: voucher.id,
                    dateCreated: new Date(),
                    createdBy: { displayName: user.displayName, id: user.id },
                    batchId
                };
                const docRef = await addDoc(collection(db, "paymentVouchers"), pv);
                console.log("Added payment voucher into Database: ", docRef.id);
                return {
                    ...pv,
                    pvId: docRef.id,
                    saleIds: voucher.sales.map((sale) => sale.id)
                };
            })
        );

        await updateSalesFromPVs(pvIds, sales);

        notification("Success", "Payment Vouchers saved into Database", "success");
    } catch (error) {
        console.log(error);
    }
}

async function updateSalesFromPVs(pvIds, sales) {
    const filteredSales = [];
    pvIds.forEach((pvId) => {
        pvId.saleIds.forEach((saleId) => {
            const f = filteredSales.find((s) => s.id === saleId);
            if (!f) {
                const sale = sales.find((s) => s.id === saleId);
                filteredSales.push(sale);
            }
        });
    });

    pvIds.forEach((pvId) => {
        pvId.saleIds.forEach((saleId) => {
            const saleIndex = filteredSales.findIndex((s) => s?.id === saleId);
            if (saleIndex !== -1) {
                const index = filteredSales[saleIndex].members.findIndex((m) => m.id === pvId.email);
                if (index !== -1) {
                    filteredSales[saleIndex].members[index] = {
                        ...filteredSales[saleIndex].members[index],
                        pvId: pvId.pvId
                    };
                } else {
                    console.log("Cannot find member");
                }
            } else {
                console.log("Cannot find sale");
            }
        });
    });

    try {
        const newSales = [...sales];
        filteredSales.forEach(async (sale) => {
            const docRef = doc(db, "sales", sale.id);
            await setDoc(docRef, { ...sale }, { merge: true });
            console.log(sale.id, " updated in Firestore");

            //update sales state
            const index = newSales.findIndex((s) => s.id === sale.id);
            newSales[index] = { ...sale };
        });
        return newSales;
    } catch (err) {
        console.log(err);
    }
}
