import { blue, green, indigo, orange, red } from "@mui/material/colors";

export const MENU_ITEMS = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Sales", url: "/sales-page", icon: "list_alt" },
    { action: "Add Sale", url: "/add-sale-page", icon: "attach_money" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Quotations", url: "/quotation-page", icon: "attach_money" },
    { action: "Products", url: "/products-page", icon: "inventory", role: "Admin" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Users Page", url: "/users-page", icon: "face", role: "Super Admin", color: green[500] },
    { action: "Divider", url: "/", icon: "", role: "Super Admin" },
    { action: "Sign Out", url: "/sign-out", icon: "logout", color: red[500] },
    { action: "Test", url: "/test-page", icon: "biotech", role: "Super Admin", color: green[500] },
    { action: "Divider", url: "/", icon: "" }
];

export const MENU_ITEMS_SIGNED_OUT = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Sign In", url: "/sign-in", icon: "login" }
];

export const COPYRIGHT_LINK = "https://www.simplynice.me";

export const WEBSITE_NAME = "simplynice.me";

export const APP_TITLE = "Simply Nice";

export const SECRET_CODE = "simplynice123";

export const HOMEPAGE_LINKS = [
    { title: "View all Sales", text: "Sales Page", link: "/sales-page", color: orange[600] },
    { title: "Yay! Add new Sale!", text: "Add New Sale", link: "/add-sale-page", color: blue[800] },
    { title: "View Payment Vouchers List", text: "Payment Vouchers", link: "/payment-vouchers-page", color: green[600] },
    { title: "View Performance Report", text: "Performance Report", link: "/performance-report-page", color: indigo[500] }
];
