import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function loadSales(user) {
    try {
        const salesRef = collection(db, "sales");

        let salesSnap = [];

        if (user.role === "Super Admin" || user.role === "Admin") {
            const q = query(salesRef, orderBy("date", "desc"));
            salesSnap = await getDocs(q);
        } else {
            const q = query(salesRef, where("involve", "array-contains", user.id), orderBy("date", "desc"));
            salesSnap = await getDocs(q);
        }

        if (!salesSnap.empty) {
            const sales = salesSnap.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id
            }));
            return sales;
        } else {
            return [];
        }
    } catch (err) {
        console.log(err);
        notification("Error", err.message, "danger");
    }
}
