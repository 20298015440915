import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function loadPV(pvId) {
    try {
        const dataRef = doc(db, "paymentVouchers", pvId);
        const dataSnap = await getDoc(dataRef);
        if (dataSnap.exists()) {
            const paymentVoucher = { ...dataSnap.data(), id: dataSnap.id };
            return paymentVoucher;
        } else {
            notification("Error", "Cannot find payment voucher", "danger");
            return null;
        }
    } catch (error) {
        console.log(error);
    }
}
