import { Route, Routes } from "react-router-dom";
import Homepage from "../pages/Homepage";
import SignIn from "../pages/SignIn";
import SignOut from "../pages/SignOut";
import SignUp from "../pages/SignUp";
import AddSalePage from "../pages/AddSalePage";
import SalesPage from "../pages/SalesPage";
import SaleCardPage from "../pages/SaleCardPage";
import TestPrintPage from "../tests/TestPrintPage";
import TestPage from "../tests/TestPage";
import PaymentVoucherPage from "../pages/PaymentVoucherPage";
import PaymentVouchersPage from "../pages/PaymentVouchersPage";
import PerformanceReportPage from "../pages/PerformaceReportPage";
import UserCardPage from "../pages/UserCardPage";
import UsersPage from "../pages/UsersPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ProducstsPage from "../pages/Products/ProductsPage";
import QuotationPage from "../pages/Quotations/QuotationPage";

export default function routes() {
    return (
        <Routes>
            <Route index element={<Homepage />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-out" element={<SignOut />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="add-sale-page" element={<AddSalePage />}>
                <Route path=":saleId" element={<AddSalePage />} />
            </Route>
            <Route path="sales-page" element={<SalesPage />} />
            <Route path="sale-card-page" element={<SaleCardPage />}>
                <Route path=":saleId" element={<SaleCardPage />}></Route>
            </Route>
            <Route path="payment-voucher-page" element={<PaymentVoucherPage />}>
                <Route path=":pvId" element={<PaymentVoucherPage />}></Route>
            </Route>
            <Route path="payment-vouchers-page" element={<PaymentVouchersPage />} />
            <Route path="performance-report-page" element={<PerformanceReportPage />} />
            <Route path="users-page" element={<UsersPage />} />
            <Route path="user-card-page" element={<UserCardPage />}>
                <Route path=":param" element={<UserCardPage />}></Route>
            </Route>
            <Route path="products-page" element={<ProducstsPage />} />
            <Route path="quotation-page" element={<QuotationPage />} />

            <Route path="forgot-password-page" element={<ForgotPasswordPage />} />
            <Route path="test-print-page" element={<TestPrintPage />} />
            <Route path="test-page" element={<TestPage />} />
        </Routes>
    );
}
