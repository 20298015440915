import React, { useState } from "react";
import { createContext } from "react";

export const PaymentVouchersContext = createContext();

export const PaymentVouchersProvider = ({ children }) => {
    const [paymentVouchers, setPaymentVouchers] = useState();

    return (
        <PaymentVouchersContext.Provider value={{ paymentVouchers, setPaymentVouchers }}>
            {children}
        </PaymentVouchersContext.Provider>
    );
};
