import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Container, Grid, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    increment,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import uuid from "react-uuid";
import ActionsList2 from "../../components/ActionsList2";
import FullDialog from "../../components/FullDialog";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import formatDate from "../../utils-functions/formatDate";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { notification } from "../../utils-functions/notification";
import ProducstsPage from "../Products/ProductsPage";
import { arrayIsEmpty } from "./../../utils-functions/arrayIsEmpty";
import formatNumber from "./../../utils-functions/formatNumber";
import Cell from "./Cell";

export default function QuotationPage() {
    const { user } = React.useContext(AuthContext);
    const [search, setSearch] = React.useState("");
    const [currentQuotation, setCurrentQuotation] = React.useState(null);
    const [quotations, setQuotations] = React.useState([]);
    const [currentItem, setCurrentItem] = useState(null);

    useEffect(() => {
        if (!user || !user.companyId) return;
        const collectionRef = collection(db, "quotations");
        const q = query(collectionRef, where("companyId", "==", user.companyId), orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const quotations = mapSnapshot(snapshot);
            setQuotations(quotations);
        });
        return unsubscribe;
    }, [user]);

    const handleAddQuotation = async () => {
        let quotationId = "Q" + "-" + formatDate(new Date());
        const docRef = doc(db, "parameters", user.companyId);
        const parameterSnapshot = await getDoc(docRef);
        if (!parameterSnapshot.exists()) {
            quotationId = quotationId + "-" + toFourDigits(1);
            await setDoc(docRef, { quotationCount: 1 });
        } else {
            const count = parameterSnapshot.data().quotationCount;
            quotationId = quotationId + "-" + toFourDigits(count);
        }
        const collectionRef = collection(db, "quotations");
        await addDoc(collectionRef, {
            quotationId: quotationId,
            companyId: user.companyId,
            date: new Date(),
            createdBy: user.id,
            status: "draft"
        });
        await setDoc(docRef, { quotationCount: increment(1) }, { merge: true });
        console.log("Created quotation: " + quotationId);
    };

    const confirmation = useConfirmation();

    async function handleDeleteQuotation(quotation) {
        const response = await confirmation("Delete?", "Confirm delete quotation?");
        if (response) {
            const docRef = doc(db, "quotations", quotation.id);
            await deleteDoc(docRef);
        }
    }

    function handleClickQuotation(quotation) {
        setCurrentQuotation(quotation);
    }

    const handleChangeDetails = (e, id) => {
        const { value } = e.target;
        setCurrentQuotation((prevState) => ({ ...prevState, [id]: value }));
    };

    const handleSaveQuotation = async () => {
        const docRef = doc(db, "quotations", currentQuotation.id);
        await setDoc(docRef, currentQuotation, { merge: true });
        notification("Success", "Quotation saved", "success");
    };

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Quotation</Title>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
            <Grid container display="flex" spacing={1}>
                <Grid item xs={12} md={3}>
                    <Grid container display="flex" spacing={1}>
                        <Grid item display={"flex"} xs={12} flexDirection="column">
                            <TextField
                                fullWidth
                                placeholder="Search / Add Quotation..."
                                size="small"
                                autoComplete="off"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Box display={"flex"}>
                                                <IconButton onClick={handleAddQuotation}>
                                                    <AddIcon />
                                                </IconButton>
                                                <IconButton disabled={!search} onClick={() => setSearch("")}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </Box>
                                        </InputAdornment>
                                    )
                                }}
                            />
                    </Grid>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper
                                sx={{ height: ["20vh", "40vh"], width: "100%", padding: "8px", overflowY: "auto" }}
                            >
                                <Grid container>
                                    {quotations.map((quotation) => (
                                        <React.Fragment key={quotation.id}>
                                            <Grid item xs={11} display="flex">
                                                <Button
                                                    color={currentQuotation?.id === quotation.id ? "error" : "primary"}
                                                    size="small"
                                                    sx={{ alignSelf: "center" }}
                                                    onClick={() => handleClickQuotation(quotation)}
                                                >
                                                    <Name bold={currentQuotation?.id === quotation.id ? true : false}>
                                                        {quotation.quotationId}
                                                    </Name>
                                                </Button>{" "}
                                                <Name bold={currentQuotation?.id === quotation.id ? true : false} ml1>
                                                    {quotation?.clientName || ""}
                                                </Name>
                                                <Name bold={currentQuotation?.id === quotation.id ? true : false} ml1>
                                                    {(quotation?.projectName || "") +
                                                        " " +
                                                        (quotation?.unitNumber || "")}
                                                </Name>
                                            </Grid>
                                            <Grid item xs={1} display="flex">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleDeleteQuotation(quotation)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Paper>
                        </Grid>
                        {currentQuotation && (
                            <Grid item display="flex" xs={12} flexDirection="column">
                                <Paper sx={{ width: "100%", padding: "8px" }}>
                                    <Grid container display={"flex"} spacing="8px">
                                        <TextFieldCustom
                                            label="Client name"
                                            id="clientName"
                                            value={currentQuotation}
                                            onChange={handleChangeDetails}
                                        />
                                        <TextFieldCustom
                                            label="Project"
                                            id="projectName"
                                            value={currentQuotation}
                                            onChange={handleChangeDetails}
                                        />
                                        <TextFieldCustom
                                            label="Unit number"
                                            id="unitNumber"
                                            value={currentQuotation}
                                            onChange={handleChangeDetails}
                                        />
                                    </Grid>
                                    <Grid item display="flex" xs={12}>
                                        <Button
                                            variant="contained"
                                            sx={{ marginTop: "8px" }}
                                            onClick={handleSaveQuotation}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container display="flex" spacing={1}>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper sx={{ height: "80vh", width: "100%", padding: "8px", overflowY: "auto" }}>
                                <QuotationDetails currentQuotation={currentQuotation} setCurrentItem={setCurrentItem} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container display="flex" spacing={1}>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper sx={{ height: "80vh", width: "100%", padding: "8px", overflowY: "auto" }}>
                                <Grid container display="flex" spacing={1}>
                                    {currentItem?.id && (
                                        <>
                                            <Grid item display={"flex"} xs={12}>
                                                <Name>{currentItem.name}</Name>
                                                <Name ml1>{currentItem.description}</Name>
                                            </Grid>

                                            <ActionsList2 collection={"products"} documentId={currentItem.productId} />
                                        </>
                                    )}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const QuotationDetails = ({ currentQuotation, setCurrentItem }) => {
    const [parts, setParts] = useState([]);
    const [currentPart, setCurrentPart] = useState(null);
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentEdit, setCurrentEdit] = useState(null);

    useEffect(() => {
        if (!currentQuotation) return;
        const parts = currentQuotation?.parts || [{ id: uuid(), description: "Description 1" }];
        setParts(parts);
    }, [currentQuotation]);

    useEffect(() => {
        if (!currentQuotation) return;
        const collectionRef = collection(db, "quotations", currentQuotation.id, "items");
        const q = query(collectionRef, orderBy("orderId"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const items = mapSnapshot(querySnapshot);
            setItems(items);
        });
        return () => {
            unsubscribe();
        };
    }, [currentQuotation]);

    const handleAddItem = (part) => {
        setCurrentPart(part);
        setOpen(true);
    };

    const addItem = async (product, part) => {
        console.log(product);

        const collectionRef = collection(db, "quotations", currentQuotation.id, "items");
        await addDoc(collectionRef, {
            ...product,
            quantity: 1,
            partId: part.id,
            orderId: items.length + 1,
            productId: product.id
        });
        toast.success(`${product.name} added successfully`);
    };

    const handleDeleteItem = async (item) => {
        const docRef = doc(db, "quotations", currentQuotation.id, "items", item.id);
        await deleteDoc(docRef);
    };

    const handleSavePart = async (part) => {
        const docRef = doc(db, "quotations", currentQuotation.id);
        await updateDoc(docRef, { parts });
    };

    const handleChangePart = (e, i) => {
        const value = e.target.value;
        const newParts = parts.map((part) => ({ ...part }));
        newParts[i].description = value;
        setParts(newParts);
    };

    const displayParts = useMemo(() => {
        const newParts = parts.map((part) => ({ ...part }));
        newParts.forEach((part) => {
            const newItems = items.filter((item) => item.partId === part.id);
            part.items = newItems;
        });
        return newParts;
    }, [parts, items]);

    const CellComponent = ({ part, item, id, type }) => {
        return (
            <Cell
                part={part}
                item={item}
                id={id}
                type={type}
                currentEdit={currentEdit}
                setCurrentEdit={setCurrentEdit}
                currentQuotation={currentQuotation}
            />
        );
    };

    const handleAddPart = async () => {
        const newParts = parts.map((part) => ({ ...part }));
        const newPart = { id: uuid(), description: "Description " + (newParts.length + 1) };
        newParts.push(newPart);
        setParts(newParts);
        const docRef = doc(db, "quotations", currentQuotation.id);
        await updateDoc(docRef, { parts: newParts });
    };

    const handleDeletePart = async (part, i) => {
        const docRef = doc(db, "quotations", currentQuotation.id);
        const newParts = parts.map((part) => ({ ...part }));
        newParts.splice(i, 1);
        setParts(newParts);
        await updateDoc(docRef, { parts: newParts });
    };

    const checkDeletePart = (part, i) => {
        if (i === 0) return true;
        if (arrayIsEmpty(part.items)) {
            return false;
        } else {
            return true;
        }
    };

    const grandTotal = useMemo(() => {
        const total = displayParts.reduce((acc, part) => {
            if (part.exclude) return acc;
            return acc + calculateTotal(part);
        }, 0);
        return total;
    }, [displayParts]);

    return (
        <Grid container display="flex" spacing={1}>
            {displayParts.map((part, i) => (
                <React.Fragment key={part.id}>
                    <Grid item xs={3} md={2} display={"flex"}>
                        <Name fs14>{`Part ${i + 1}: `}</Name>
                    </Grid>
                    <Grid item xs={9} md={6} display={"flex"}>
                        <TextField
                            size="small"
                            value={part.description}
                            fullWidth
                            onBlur={() => handleSavePart(part)}
                            onChange={(e) => handleChangePart(e, i)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} display={"flex"} justifyContent={"flex-end"}>
                        <Button variant="outlined" onClick={() => handleAddItem(part)} size="small">
                            Add Item
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => handleAddPart()}
                            size="small"
                            sx={{ marginLeft: "8px" }}
                        >
                            New Part
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => handleDeletePart(part, i)}
                            size="small"
                            color="error"
                            sx={{ marginLeft: "8px" }}
                            disabled={checkDeletePart(part, i)}
                        >
                            Remove
                        </Button>
                    </Grid>
                    {part.items?.map((item) => (
                        <React.Fragment key={item.id}>
                            <Grid item xs={6} md={3} display={"flex"}>
                                <Name onClick={() => setCurrentItem(item)} cp>
                                    {item.name}
                                </Name>
                            </Grid>
                            <Grid item xs={6} md={3} display={"flex"} justifyContent={["flex-end", "flex-start"]}>
                                <CellComponent part={part} item={item} id="description" />
                            </Grid>
                            <Grid item xs={2} md={1} display={"flex"} justifyContent={"flex-end"}>
                                <CellComponent part={part} item={item} id="quantity" type="number" />
                            </Grid>
                            <Grid item xs={4} md={2} display={"flex"} justifyContent={"flex-end"}>
                                <CellComponent part={part} item={item} id="price" type="number" />
                            </Grid>
                            <Grid item xs={5} md={2} display={"flex"} justifyContent={"flex-end"}>
                                <Name>{formatNumber(item.price * item.quantity)}</Name>
                            </Grid>
                            <Grid item xs={1} md={1} display={"flex"} justifyContent={"flex-end"}>
                                <IconButton size="small" onClick={() => handleDeleteItem(item)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={11} display="flex" justifyContent={"flex-end"}>
                        <Name>{`Total: ${formatNumber(calculateTotal(part))}`}</Name>
                    </Grid>
                    <Grid item xs={1} display={"flex"}></Grid>
                </React.Fragment>
            ))}
            <GridDivider />
            <Grid item xs={11} display="flex" justifyContent={"flex-end"}>
                <Name>{`Grand Total: ${formatNumber(grandTotal)}`}</Name>
            </Grid>
            <Grid item xs={1} display={"flex"}></Grid>
            <FullDialog
                open={open}
                handleClose={() => setOpen(false)}
                title={"Add Item"}
                Component={<ProducstsPage addItem={addItem} part={currentPart} />}
            />
        </Grid>
    );
};

const calculateTotal = (part) => {
    const total = part.items.reduce((acc, item) => {
        const price = item.price || 0;
        const quantity = item.quantity || 0;
        return acc + price * quantity;
    }, 0);
    return total;
};

function TextFieldCustom({ label, id, value, onChange, xs = 12 }) {
    return (
        <Grid item xs={xs} display="flex">
            <TextField
                size="small"
                fullWidth
                placeholder={label}
                label={label}
                autoComplete="off"
                value={value?.[id] || ""}
                onChange={(e) => onChange(e, id)}
            />
        </Grid>
    );
}

function toFourDigits(num) {
    return String(num).padStart(4, "0");
}
