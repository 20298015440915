import { addDoc, collection, doc, runTransaction } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";

export default async function addAction(action, collectionId, documentId) {
    try {
        const collectionRef = collection(db, collectionId, documentId, "actions");
        const actionRef = await addDoc(collectionRef, action);
        console.log("Action added to collection " + collectionId + " with docId " + documentId);

        // update lastActions for document
        await runTransaction(db, async (transaction) => {
            const docRef = doc(db, collectionId, documentId);
            const docSnap = await transaction.get(docRef);
            const document = mapDocSnapshot(docSnap);
            if (!document) return;
            if (document.lastActions) {
                const lastActions = [...document.lastActions];
                lastActions.splice(0, 0, { ...action, id: actionRef.id });
                transaction.update(docRef, { lastActions: lastActions.slice(0, 3) });
            } else {
                const lastActions = [{ ...action, id: actionRef.id }];
                transaction.update(docRef, { lastActions: lastActions });
            }
        });
        console.log(collectionId + " with docId " + documentId + " updated");
        return { ...action, id: actionRef };
    } catch (error) {
        console.log(error);
    }
}
