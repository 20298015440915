import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function loadActions(collectionId, documentId, setActions, setLoading) {
    try {
        setLoading(true);
        const dataRef = collection(db, collectionId, documentId, "actions");
        const unsubscribe = onSnapshot(dataRef, (snapshot) => {
            const actions = mapSnapshot(snapshot);
            if (!actions) {
                setLoading(false);
                setActions();
                return;
            }
            actions.sort((a, b) => b.date - a.date);
            setActions(actions);
            setLoading(false);
        });
        return unsubscribe;
    } catch (error) {
        console.log(error);
    }
}
