import { TextField } from "@mui/material";
import { GridFlexBox } from "../themes/themes";

export default function DateSelect({ date, setDate }) {
    const handleChange = (e) => {
        const { value } = e.target;
        setDate(value);
    };

    return (
        <GridFlexBox p2>
            <TextField
                name="date"
                required
                fullWidth
                id="date"
                label="Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                autoFocus
                value={date}
                onChange={handleChange}
                size="small"
            />
        </GridFlexBox>
    );
}
