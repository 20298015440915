import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SaleDetails from "../components/SaleDetails";
import { AuthContext } from "../context/AuthContext";
import { SalesContext } from "../context/SalesContext";
import SelectComponent from "../components/SelectComponent";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import FullDialog from "../components/FullDialog";
import GeneratePaymentVouchers from "../components/GeneratePaymentVouchers";
import { GridContainer, GridDivider, GridFlexBox, Loading, Status } from "../themes/themes";
import addPaymentVouchers from "../functions/addPaymentVouchers";
import loadSalesRT from "../functions/loadSalesRT";
import { StateContext } from "../context/StateContext";

const FILTERS = { displayAction: true };

export default function SalesPage() {
    const { sales, setSales, setUnsubscribe } = useContext(SalesContext);
    const { user } = useContext(AuthContext);
    const { state, setState } = useContext(StateContext);

    const [loading, setLoading] = useState(false);
    const [select, setSelect] = useState([]);
    const [list, setList] = useState([]);
    const [openVouchers, setOpenVouchers] = useState(false);
    const [selectedSales, setSelectedSales] = useState([]);

    const [filter, setFilter] = useState(FILTERS);

    const navigate = useNavigate();

    useEffect(() => {
        if (!sales) {
            getData();
        }
        // Do not unsubscribe on unmount
    }, []);

    const getData = () => {
        const unsubscribe = loadSalesRT(user, setSales, setLoading);
        setUnsubscribe(() => () => unsubscribe()); // function that returns a callback function
        return unsubscribe;
    };

    useEffect(() => {
        setTimeout(() => {
            if (state) {
                if (state.scrollRef) {
                    window.scrollTo({
                        top: state.scrollRef,
                        left: 0,
                        behavior: "smooth"
                    });
                }
            }
        }, 500);
    }, []);

    const handleOpen = async (sale) => {
        setState((state) => ({ ...state, scrollRef: window.scrollY }));
        navigate(`/sale-card-page/${sale.id}`);
    };

    const handleClickSelect = (sale) => {
        checkSelect(select, sale, setSelect, list, setList);
    };

    const handleClick = () => {
        const selectedSales = getSalesFromSelect(select, sales);
        setSelectedSales(selectedSales);
        setOpenVouchers(true);
    };

    const handleSelectAll = () => {
        selectAll(sales, setList, setSelect);
    };

    const handleGenerate = async (paymentVouchers) => {
        console.log(paymentVouchers);
        await addPaymentVouchers(paymentVouchers, sales, user, setSales);
        setOpenVouchers(false);
    };

    const handleClickFilter = (f) => {
        setFilter((filter) => ({ ...filter, [f]: !filter[f] }));
    };

    return (
        <Box m={1}>
            <Container component="main" maxWidth="sm">
                <GridContainer>
                    <GridFlexBox>
                        <Typography variant="h5">Sales Page</Typography>
                    </GridFlexBox>
                    <GridDivider />
                    <GridFlexBox fe>
                        <Status
                            fs12
                            cp
                            greyed={!filter["displayAction"]}
                            onClick={() => handleClickFilter("displayAction")}
                        >
                            Display Actions
                        </Status>
                    </GridFlexBox>
                    <GridDivider />
                    <Loading loading={loading} />
                    {sales &&
                        sales.map((sale) => (
                            <SaleDetails
                                key={sale.id}
                                handleOpen={handleOpen}
                                sale={sale}
                                select={select}
                                handleClickSelect={handleClickSelect}
                                displayAction={filter["displayAction"]}
                            />
                        ))}
                    <Grid item>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </Grid>
                </GridContainer>
            </Container>
            <SelectComponent
                open={arrayIsEmpty(select) ? false : true}
                list={list}
                handleClick={handleClick}
                selectAll={handleSelectAll}
                unselect={() => {
                    setSelect([]);
                    setList([]);
                }}
            />
            <FullDialog
                open={openVouchers}
                Component={<GeneratePaymentVouchers sales={selectedSales} handleGenerate={handleGenerate} />}
                title="Generate Vouchers"
                handleClose={() => {
                    setOpenVouchers(false);
                }}
            />
        </Box>
    );
}

function selectAll(sales, setList, setSelect) {
    const newList = [];
    const newSelect = [];
    sales.forEach((sale) => {
        newSelect.push(sale.id);
        newList.push(sale.clientName + " (" + sale.propertyName + " " + sale.unitNumber + ")");
    });
    setList(newList);
    setSelect(newSelect);
}

function getSalesFromSelect(select, sales) {
    const selectedSales = [];
    select.forEach((s) => {
        const f = sales.find((sale) => sale.id === s);
        if (f) selectedSales.push(f);
    });

    return selectedSales;
}

function checkSelect(select, sale, setSelect, list, setList) {
    const index = select.findIndex((doc) => doc === sale.id);
    if (index === -1) {
        const newSelect = [...select];
        const newList = [...list];
        newSelect.push(sale.id);
        newList.push(sale.clientName + " (" + sale.propertyName + " " + sale.unitNumber + ")");
        setSelect(newSelect);
        setList(newList);
    } else {
        const newSelect = [...select];
        const newList = [...list];
        newSelect.splice(index, 1);
        newList.splice(index, 1);
        setSelect(newSelect);
        setList(newList);
    }
}
