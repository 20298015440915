import { Avatar, Button, Container, Icon, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ListContext } from "../context-utils/ListContext";
import { SalesContext } from "../context/SalesContext";
import { UsersContext } from "../context/UsersContext";
import addSale from "../functions/addSale";
import formatDate from "../utils-functions/formatDate";
import convertDate from "../utils-functions/convertDate";
import loadUsers from "../functions/loadUsers";
import updateSale from "../functions/updateSale";
import loadSalesRT from "../functions/loadSalesRT";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, Loading, TextFieldC, TextFieldIA } from "../themes/themes";
import deleteSale from "../functions/deleteSale";
import { ConfirmationContext } from "../context-utils/ConfirmationContext";
import isAdmin from "../utils-functions/isAdmin";
import { blue } from "@mui/material/colors";

const MEMBER = { displayName: "", percentage: "", role: "", id: "", netCommission: "" };
const STATE = {
    date: formatDate(new Date()),
    clientName: "",
    propertyName: "",
    unitNumber: "",
    saleAmount: "",
    remark: "",
    profit: 0
};

export default function AddSalePage() {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState([{ ...MEMBER }]); //Must use spread operator for Object inside array
    const [state, setState] = useState(STATE);
    const [sale, setSale] = useState();

    const { openGlobalList } = useContext(ListContext);
    const { users } = useContext(UsersContext);
    const { user } = useContext(AuthContext);
    const { sales, setSales, setUnsubscribe } = useContext(SalesContext);

    const { openConfirmation } = useContext(ConfirmationContext);

    const { saleId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            if (saleId) {
                if (sales) {
                    const sale = sales.find((doc) => doc.id === saleId);
                    if (sale) {
                        const { members, ...others } = sale;
                        setState({
                            ...others,
                            date: formatDate(convertDate(sale.date))
                        });
                        setMembers(members);
                        setSale(sale);
                    }
                } else {
                    const unsubscribe = loadSalesRT(user, setSales, setLoading);
                    setUnsubscribe(() => () => unsubscribe());
                }
            }
        };
        loadData();
    }, [sales]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(state);
        setLoading(true);
        if (!saleId) {
            await addSale(state, members, user);
            setState(STATE);
        } else {
            await updateSale(state, members, user, saleId);
        }
        setLoading(false);
    };

    const addMember = () => {
        const newMembers = [...members, { ...MEMBER }]; //Must use Spread operator for deep copy
        setMembers(newMembers);
        console.log(newMembers);
    };

    const removeMember = (i) => {
        if (i === 0) return;

        const newMembers = [...members];
        console.log(i);
        newMembers.splice(i, 1);
        setMembers(newMembers);
    };

    const clickMember = async (i) => {
        let usersList = users;
        if (!users) {
            setLoading(true);
            usersList = await loadUsers(user);
            setLoading(false);
        }
        const response = await openGlobalList(usersList, "Select member", "displayName", "face", false, true);
        if (response) {
            const newMembers = [...members];
            newMembers[i]["displayName"] = response.displayName;
            newMembers[i]["id"] = response.id;
            setMembers(newMembers);
        }
        console.log(response);
    };

    const handleChange = (e) => {
        const { id, name, value } = e.target;
        const newMembers = [...members];
        newMembers[id][name] = value;
        setMembers(newMembers);
    };

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        const newState = { ...state, [name]: value };
        setState(newState);
    };

    const handleDelete = async () => {
        console.log("Delete");
        const response = await openConfirmation("Delete sale", "Press ok to confirm");
        if (response) {
            await deleteSale(saleId);
            navigate("/sales-page");
        }
    };

    const checkUser = () => {
        if (user.role === "Admin" || user.role === "Super Admin") {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Container maxWidth="md">
            <Box m={2}>
                <GridContainer>
                    <GridFlexBox>
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <Icon>attach_money</Icon>
                        </Avatar>
                    </GridFlexBox>
                    <GridFlexBox>
                        <Typography component="h1" variant="h5">
                            {!saleId ? "Add Sale" : "Edit Sale"}
                        </Typography>
                    </GridFlexBox>
                    <GridDivider ptb={16} />
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <GridContainer>
                            <TextFieldC
                                name="date"
                                label="Date"
                                date
                                autoFocus
                                value={state.date}
                                onChange={handleChange2}
                                small
                            />
                            <TextFieldC
                                name="clientName"
                                label="Client name"
                                value={state.clientName}
                                onChange={handleChange2}
                                small
                            />
                            <TextFieldC
                                name="propertyName"
                                label="Property Name"
                                value={state.propertyName}
                                onChange={handleChange2}
                                small
                            />
                            <TextFieldC
                                name="unitNumber"
                                label="Unit Number"
                                value={state.unitNumber}
                                onChange={handleChange2}
                                small
                                required={false}
                            />
                            <TextFieldC
                                name="saleAmount"
                                label="Sale Amount"
                                type="number"
                                value={state.saleAmount}
                                onChange={handleChange2}
                                small
                            />
                            <TextFieldC
                                name="remark"
                                label="Remarks"
                                value={state.remark}
                                onChange={handleChange2}
                                small
                                required={false}
                            />
                            <GridDivider />
                            {saleId && checkUser() && (
                                <>
                                    <TextFieldC
                                        name="profit"
                                        label="Profit"
                                        type="number"
                                        value={state.profit}
                                        onChange={handleChange2}
                                        small
                                        labelColor={blue[400]}
                                    />
                                    <GridDivider />
                                </>
                            )}
                            {members.map((member, i) => (
                                <React.Fragment key={`member${i}`}>
                                    <GridFlexBox xs={6} md={3}>
                                        <TextFieldIA
                                            name="member"
                                            id={`${i}`}
                                            label={`Member ${i + 1}`}
                                            value={members[i].displayName || ""}
                                            handleClick={() => clickMember(i)}
                                        />
                                    </GridFlexBox>
                                    <GridFlexBox item xs={6} md={3}>
                                        <TextFieldC
                                            name="role"
                                            id={`${i}`}
                                            label={`Role ${i + 1}`}
                                            small
                                            value={members[i].role || ""}
                                            onChange={handleChange}
                                            shrinkLabel
                                        />
                                    </GridFlexBox>
                                    <GridFlexBox xs={4} md={2}>
                                        {isAdmin(user) && (
                                            <TextFieldC
                                                name="percentage"
                                                id={`${i}`}
                                                label={`Percentage ${i + 1}`}
                                                type="number"
                                                small
                                                value={members[i].percentage || 0}
                                                onChange={handleChange}
                                                inputProps={{
                                                    min: 0,
                                                    max: 100,
                                                    step: 0.01
                                                }}
                                            />
                                        )}
                                    </GridFlexBox>
                                    <GridFlexBox xs={4} md={2}>
                                        {isAdmin(user) && (
                                            <TextFieldC
                                                name="netCommission"
                                                id={`${i}`}
                                                small
                                                label={`Net Comm ${i + 1}`}
                                                type="number"
                                                value={members[i].netCommission || 0}
                                                onChange={handleChange}
                                                inputProps={{
                                                    min: 0
                                                }}
                                            />
                                        )}
                                    </GridFlexBox>
                                    <GridFlexBox item xs={4} md={2}>
                                        <IconButton onClick={addMember}>
                                            <Icon fontSize="large">add</Icon>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                removeMember(i);
                                            }}
                                        >
                                            <Icon
                                                fontSize="large"
                                                sx={{
                                                    display: i ? "block" : "none"
                                                }}
                                            >
                                                remove
                                            </Icon>
                                        </IconButton>
                                    </GridFlexBox>
                                    <GridDivider />
                                </React.Fragment>
                            ))}
                            <Button fullWidth type="submit" variant="contained" sx={{ mt: 2 }}>
                                {saleId ? "Update Sale" : "Add"}
                            </Button>
                            <Loading loading={loading} />
                        </GridContainer>
                    </form>
                    <GridContainer>
                        <GridFlexBox fs>
                            {saleId && (
                                <ButtonC small submit color="warning" onClick={() => navigate(-1)} mtb1>
                                    Back
                                </ButtonC>
                            )}
                        </GridFlexBox>
                        {saleId && canDelete(user, sale) && (
                            <GridFlexBox fs>
                                <ButtonC small color="error" onClick={handleDelete}>
                                    delete
                                </ButtonC>
                            </GridFlexBox>
                        )}
                    </GridContainer>
                </GridContainer>
            </Box>
        </Container>
    );
}

const canDelete = (user, sale) => {
    if (!sale) return false;
    if (sale.createdBy.id === user.id) return true;
    if (user.role === "Super Admin" || user.role === "Admin") return true;
};
