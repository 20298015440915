import { deleteDoc, doc, runTransaction } from "firebase/firestore";
import { db, storage } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";
import { deleteObject, ref } from "firebase/storage";
import getFileName from "../utils-functions/getFileName";
import isFileImage from "../utils-functions/isFileImage";

export default async function deleteAction(action, actions, collectionId, documentId) {
    console.log(actions);
    try {
        const dataRef = doc(db, collectionId, documentId, "actions", action.id);
        await deleteDoc(dataRef);
        console.log("Action " + action.id + " deleted");

        // delete files if action has files
        if (action.files) {
            action.files.forEach(async (file) => {
                try {
                    const fullPath = file.folderRef + "/" + file.name;
                    const storageRef = ref(storage, fullPath);

                    await deleteObject(storageRef);
                    console.log(fullPath + " deleted");

                    // Delete the Thumbnail if the file is an image
                    if (isFileImage(file)) {
                        const thumbnailFullPath =
                            file.folderRef + "/thumbnails/" + getFileName(file.name) + "_1080x1080.jpeg";
                        const thumbnailRef = ref(storage, thumbnailFullPath);
                        await deleteObject(thumbnailRef);
                        console.log(thumbnailFullPath + " deleted");
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        }

        // update lastActions for document
        await runTransaction(db, async (transaction) => {
            const docRef = doc(db, collectionId, documentId);
            const docSnap = await transaction.get(docRef);
            const document = mapDocSnapshot(docSnap);
            if (!document) return;

            const newActions = [...actions];
            const index = newActions.findIndex((d) => d.id === action.id);
            if (index !== -1) {
                newActions.splice(index, 1);
                transaction.update(docRef, { lastActions: newActions.slice(0, 3) });
            }
        });
        console.log(collectionId + " with id " + documentId + " updated");
    } catch (error) {
        console.log(error);
    }
}
