export default function flattenMembers(sales) {
    if (sales) {
        const uniqueMembers = [];
        sales.forEach((sale) => {
            sale.members.forEach((member) => {
                const index = uniqueMembers.findIndex((m) => m.id === member.id);
                // eslint-disable-next-line
                const { members, ...s } = sale;
                const memberData = { displayName: member.displayName, id: member.id };
                const saleData = {
                    ...s,
                    percentage: member.percentage,
                    pvId: member.pvId,
                    role: member.role,
                    commission: (parseFloat(member.percentage) * parseFloat(sale.profit)) / 100
                };
                if (index === -1) {
                    uniqueMembers.push({ ...memberData, sales: [saleData] });
                } else {
                    uniqueMembers[index].sales.push(saleData);
                }
            });
        });

        uniqueMembers.forEach((member, i) => {
            let totalSales = 0;
            let totalProfit = 0;
            member.sales.forEach((sale) => {
                totalSales += parseFloat(sale.saleAmount);
                totalProfit += parseFloat(sale.commission);
            });
            uniqueMembers[i] = { ...uniqueMembers[i], totalProfit, totalSales };
        });
        uniqueMembers.sort((a, b) => b.totalProfit - a.totalProfit);
        console.log(uniqueMembers);
        return uniqueMembers;
    }
}
