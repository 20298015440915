export default function formatNumberWithSeparator(number) {
    if (!number) return "";
    const value = number.toString();
    const parts = value.split(".");
    // remove non numeric [0-9] characters
    parts[0] = parts[0].replace(/\D/g, "");
    // add commas back
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts[1]) {
        // remove non numeric and more than 2 characters for decimals
        parts[1] = parts[1].substring(0, 2).replace(/\D/g, "");
    }
    const result = parts.join(".");
    return result;
}
