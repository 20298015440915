import React, { useEffect, useState } from "react";
import { GridFlexBox, GridItem, IconC, Loading, Name } from "../themes/themes";
import convertDate from "../utils-functions/convertDate";
import moment from "moment/moment";
import { blue, red } from "@mui/material/colors";
import isFileImage from "../utils-functions/isFileImage";
import { IconButton, Link } from "@mui/material";
import FullDialog from "./FullDialog";
import GalleryComponent from "./GalleryComponent";
import getThumbnail from "../functions/getThumbnails";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { InputContext } from "../context-utils/InputContext";
import loadActions from "../functions/loadActions";
import editAction from "../functions/editAction";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import deleteAction from "../functions/deleteAction";

export default function ActionsList2({ collection, documentId }) {
    const [imagesURL, setImagesURL] = useState();
    const [loading, setLoading] = useState();
    const [open, setOpen] = useState(false);
    const [actions, setActions] = useState();
    const [actions_, setActions_] = useState();
    const { user } = useContext(AuthContext);
    const { openInputDialog } = useContext(InputContext);

    useEffect(() => {
        if (!collection || !documentId) {
            setActions();
            return;
        }
        const unsubscribe = loadActions(collection, documentId, setActions, setLoading);

        return unsubscribe;
    }, [collection, documentId]);

    useEffect(() => {
        getData();
    }, [actions]);

    const getData = async () => {
        await checkThumbnails(actions, setActions_);
    };

    const handleClickImage = (action) => {
        const imagesURL = [];
        action.files.forEach((file) => {
            if (isFileImage(file)) {
                imagesURL.push(file.thumbnailURL || file.downloadURL);
            }
        });
        setImagesURL(imagesURL);
        setOpen(true);
    };

    const handleEditAction = async (action) => {
        await editAction(action, collection, documentId);
    };

    const handleEdit = async (action) => {
        const response = await openInputDialog("Edit Action", "Edit comment", "Comment", action.comment);
        const newAction = { ...action, comment: response || "" };
        handleEditAction(newAction);
    };

    const confirmation = useConfirmation();

    const handleDeleteAction = async (action) => {
        const response = await confirmation("Delete action", "Press OK to confirm delete");
        if (response) {
            await deleteAction(action, actions, collection, documentId);
        }
    };

    const display = (action) => {
        if (user.role === "Super Admin") {
            return true;
        } else {
            if (action.email === user.email) {
                return true;
            } else {
                return false;
            }
        }
    };

    return (
        <React.Fragment>
            {actions_ &&
                actions_.map((action, i) => (
                    <React.Fragment key={action.id}>
                        <Loading loading={loading} />
                        {displayName(actions_, i) && (
                            <GridItem fs fs12 bold mt4>
                                {action.name}
                            </GridItem>
                        )}
                        <GridItem fs12 fs w="35%" blueColor>
                            {moment(convertDate(action.date)).format("DD-MMM-YY LT")}
                        </GridItem>
                        <GridItem fs w="53%" blueColor pl1>
                            {action.comment}
                        </GridItem>
                        <GridFlexBox fe w="12%" blueColor>
                            {display(action) && (
                                <>
                                    <IconC fs16 color={blue[800]} cp onClick={() => handleEdit(action)}>
                                        edit
                                    </IconC>
                                    <IconC fs16 color={red[500]} cp onClick={() => handleDeleteAction(action)}>
                                        clear
                                    </IconC>
                                </>
                            )}
                        </GridFlexBox>
                        <DisplayFiles action={action} handleClickImage={handleClickImage} />

                        <FullDialog
                            Component={<GalleryComponent imagesURL={imagesURL} />}
                            open={open}
                            handleClose={() => setOpen(false)}
                            title="Image Gallery"
                        />
                    </React.Fragment>
                ))}
        </React.Fragment>
    );
}

const displayName = (actions, i) => {
    if (i === 0) return true;
    if (actions[i].name === actions[i - 1].name) {
        return false;
    } else {
        return true;
    }
};

async function checkThumbnails(actions, setActions_) {
    if (actions) {
        const newActions = [...actions];
        await Promise.all(
            actions.map(async (action, i) => {
                if (action.files) {
                    await Promise.all(
                        action.files.map(async (file, j) => {
                            if (isFileImage(file)) {
                                const thumbnailURL = await getThumbnail(file);
                                if (thumbnailURL) {
                                    newActions[i].files[j] = { ...newActions[i].files[j], thumbnailURL };
                                }
                                return thumbnailURL;
                            }
                            return Promise.resolve();
                        })
                    );
                    return Promise.resolve();
                }
            })
        );
        setActions_(newActions);
    } else {
        setActions_();
    }
}

function DisplayFiles({ action, handleClickImage }) {
    const [numberOfImages, setNumberOfImages] = useState(0);
    const [filesToDisplay, setFilesToDisplay] = useState();

    useEffect(() => {
        if (action.files) {
            const newFiles = [...action.files];
            newFiles.sort((a, b) => {
                if (isFileImage(a) && isFileImage(b)) {
                    return 0;
                }
                return -1;
            });

            let numberOfImages = 0;
            const newFiles2 = [];
            newFiles.forEach((file) => {
                if (isFileImage(file)) {
                    numberOfImages++;
                    if (numberOfImages < 4) {
                        newFiles2.push(file);
                    }
                } else {
                    newFiles2.push(file);
                }
            });
            setFilesToDisplay(newFiles2);
            setNumberOfImages(numberOfImages);
        }
    }, []);

    return (
        <React.Fragment>
            {filesToDisplay &&
                filesToDisplay.map((file) => (
                    <React.Fragment key={file.downloadURL}>
                        <GridItem fs w="35%">
                            {isFileImage(file) ? (
                                <img
                                    width={"100%"}
                                    src={file.thumbnailURL || file.downloadURL}
                                    alt=""
                                    onClick={() => handleClickImage(action)}
                                    style={{ cursor: "pointer" }}
                                />
                            ) : (
                                <IconC fs30>
                                    <a href={file.downloadURL} target="_blank" rel="noreferrer">
                                        article
                                    </a>
                                </IconC>
                            )}
                        </GridItem>
                        <GridItem w="65%" fs12 fs>
                            <Link href={file.downloadURL} target="_blank" rel="noreferrer" sx={{ paddingLeft: 1 }}>
                                {file.name}
                            </Link>
                        </GridItem>
                    </React.Fragment>
                ))}
            {numberOfImages > 3 && (
                <React.Fragment>
                    <GridFlexBox w="40%" gap={2}>
                        <IconButton onClick={() => handleClickImage(action)}>
                            <IconC>more_horiz</IconC>
                            <Name ml1>More images ({numberOfImages - 3})</Name>
                        </IconButton>
                    </GridFlexBox>
                    <GridFlexBox w="60%"></GridFlexBox>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
