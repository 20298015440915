import { Container } from "@mui/system";
import { GridContainer, GridDivider, GridFlexBox, GridItem, Name, Title } from "../themes/themes";
import { AuthContext } from "../context/AuthContext";
import React, { useContext, useState } from "react";
import { SalesContext } from "../context/SalesContext";
import { useEffect } from "react";
import loadSales from "../functions/loadSales";
import calculateProfit from "../functions/calculateProfit";
import formatNumber from "../utils-functions/formatNumber";
import flattenMembers from "../functions/flattenMembers";
import formatDate from "../utils-functions/formatDate";
import convertDate from "../utils-functions/convertDate";
import isAdmin from "../utils-functions/isAdmin";

export default function PerformanceReportPage() {
    const { user } = useContext(AuthContext);
    const { sales, setSales } = useContext(SalesContext);
    const [totalProfit, setTotalProfit] = useState();
    const [totalSales, setTotalSales] = useState();
    const [members, setMembers] = useState();

    useEffect(() => {
        if (!sales) getData();
    }, []);

    useEffect(() => {
        if (sales) {
            const members = flattenMembers(sales);
            setMembers(members);
            const { totalSales, totalProfit } = calculateProfit("company", sales);
            setTotalSales(totalSales);
            setTotalProfit(totalProfit);
        }
    }, [sales]);

    const getData = async () => {
        const sales = await loadSales(user);
        console.log(sales);
        setSales(sales);
    };

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox>
                    <Title>Performance Report Page</Title>
                </GridFlexBox>
                <GridDivider />
                {isAdmin(user) && <CompanyReport sales={sales} totalSales={totalSales} totalProfit={totalProfit} />}
                {members && members.map((member) => <MemberReport key={member.id} member={member} user={user} />)}
            </GridContainer>
        </Container>
    );
}

function MemberReport({ member, user }) {
    return (
        displayReport(member, user) && (
            <React.Fragment key={member.id}>
                <GridFlexBox fs>
                    <Name bold>{member.displayName}</Name>
                </GridFlexBox>
                {member.sales.map((sale) => (
                    <React.Fragment key={sale.id}>
                        <GridFlexBox fs w="20%">
                            <Name fs12>{formatDate(convertDate(sale.date))}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="30%" fs>
                            <Name fs12>{sale.propertyName}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="20%" fs>
                            <Name fs12>{sale.unitNumber}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="30%" fe>
                            <Name fs12>{formatNumber(sale.commission)}</Name>
                        </GridFlexBox>
                    </React.Fragment>
                ))}
                {isAdmin(user) && (
                    <>
                        <GridItem w="70%" fe fs12>
                            Total Sales:
                        </GridItem>
                        <GridItem w="30%" fe fs12 bold>
                            {formatNumber(member.totalSales)}
                        </GridItem>
                    </>
                )}
                <GridItem w="70%" fe fs12>
                    Total Profit:
                </GridItem>
                <GridItem w="30%" fe fs12 bold>
                    {formatNumber(member.totalProfit)}
                </GridItem>
                <GridItem w="70%"></GridItem>
                <GridFlexBox w="30%" fe fs12 bold>
                    {isAdmin(user) && (
                        <Name fs12 bold>
                            {formatNumber((member.totalProfit / member.totalSales) * 100)}%
                        </Name>
                    )}
                </GridFlexBox>
                <GridDivider />
            </React.Fragment>
        )
    );
}

const displayReport = (member, user) => {
    if (isAdmin(user)) return true;
    if (user.id === member.id) return true;
    return false;
};

function CompanyReport({ sales, totalSales, totalProfit }) {
    return (
        <React.Fragment>
            <GridFlexBox fs>
                <Name bold>Company</Name>
            </GridFlexBox>
            <GridFlexBox fs w="40%">
                <Name>Number of Sales:</Name>
            </GridFlexBox>
            <GridFlexBox fe w="30%">
                <Name>{sales && formatNumber(sales.length, 0)}</Name>
            </GridFlexBox>
            <GridFlexBox w="30%"></GridFlexBox>
            <GridFlexBox fs w="40%">
                <Name>Total Sales:</Name>
            </GridFlexBox>
            <GridFlexBox fe w="30%">
                <Name>{formatNumber(totalSales)}</Name>
            </GridFlexBox>
            <GridFlexBox w="30%"></GridFlexBox>
            <GridFlexBox fs w="40%">
                <Name>Total Profit:</Name>
            </GridFlexBox>
            <GridFlexBox fe w="30%">
                <Name>{formatNumber(totalProfit)}</Name>
            </GridFlexBox>
            <GridFlexBox w="30%">
                <Name>{formatNumber((totalProfit / totalSales) * 100)}%</Name>
            </GridFlexBox>
            <GridDivider />
        </React.Fragment>
    );
}
