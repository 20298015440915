import { GridFlexBox, IconC } from "../themes/themes";

export function Checkbox({ select, id, setSelect }) {
    const handleClick = () => {
        const newSelect = [...select];
        if (select.includes(id)) {
            const index = select.findIndex((s) => s === id);
            newSelect.splice(index, 1);
            setSelect(newSelect);
        } else {
            newSelect.push(id);
            setSelect(newSelect);
        }
    };
    return (
        <GridFlexBox fs>
            <IconC cp onClick={handleClick}>
                {select.includes(id) ? "check_box" : "check_box_outline_blank"}
            </IconC>
        </GridFlexBox>
    );
}
