import { Icon, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";
import React, { useContext } from "react";
import convertDate from "../utils-functions/convertDate";
import formatDate from "../utils-functions/formatDate";
import formatNumber from "../utils-functions/formatNumber";
import SaleMembersList from "./SaleMembersList";
import { GridDivider, GridFlexBox, IconC } from "../themes/themes";
import ActionsList from "./ActionsList";
import { AuthContext } from "../context/AuthContext";
import isAdmin from "../utils-functions/isAdmin";

export default function SaleDetails({ handleOpen, sale, select = [], handleClickSelect, displayAction }) {
    const { user } = useContext(AuthContext);

    return (
        <React.Fragment>
            <GridFlexBox fs w="32%">
                {isAdmin(user) && (
                    <IconC
                        cp
                        display={handleClickSelect ? "block" : "none"}
                        onClick={() => {
                            handleClickSelect(sale);
                        }}
                    >
                        {select.includes(sale.id) ? "check_box" : "check_box_outline_blank"}
                    </IconC>
                )}
                <IconC
                    onClick={() => {
                        handleOpen(sale);
                    }}
                    display={handleOpen ? "block" : "none"}
                    cp
                >
                    open_in_new
                </IconC>
                <Typography variant="body2" fontSize={12} style={{ paddingLeft: "3px" }} noWrap>{`${formatDate(
                    convertDate(sale.date)
                )}`}</Typography>
            </GridFlexBox>
            <GridFlexBox fs w="33%">
                <Typography variant="body2">{`${sale.propertyName} ${sale.unitNumber}`}</Typography>
            </GridFlexBox>
            <GridFlexBox fe w="35%">
                <Icon fontSize="small" style={{ color: orange[600] }}>
                    sell
                </Icon>
                <Typography variant="body2" style={{ paddingLeft: "3px" }}>{`${formatNumber(
                    sale.saleAmount
                )}`}</Typography>
            </GridFlexBox>
            <GridFlexBox fs gap="0" p2>
                <Icon
                    fontSize="small"
                    style={{
                        color: "darkgreen",
                        fontWeight: "bold"
                    }}
                >
                    perm_identity
                </Icon>
                <Typography
                    variant="body2"
                    style={{
                        paddingLeft: "3px",
                        color: "darkgreen",
                        fontWeight: "bold"
                    }}
                >{`${sale.clientName}`}</Typography>
            </GridFlexBox>
            {sale.members.map((member, i) => (
                <SaleMembersList member={member} key={i} />
            ))}
            {sale.lastActions && handleOpen && displayAction && <ActionsList actions={sale.lastActions} />}
            <GridDivider />
        </React.Fragment>
    );
}
