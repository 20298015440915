import {
    Button,
    Divider,
    Grid,
    Icon,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Typography
} from "@mui/material";
import { blue, grey, orange } from "@mui/material/colors";
import { Box } from "@mui/system";

export const GridContainer = (props) => {
    const { s1, s2, s3, spacing } = props;
    let s = 1;
    s = s1 ? 1 : s;
    s = s2 ? 2 : s;
    s = s3 ? 3 : s;

    if (spacing) s = spacing;

    return (
        <Grid container spacing={s} justifyContent="center" alignItems="center">
            {props.children}
        </Grid>
    );
};

export const GridFlexBox = (props) => {
    let {
        justifyContent,
        xs,
        md,
        fs,
        fe,
        gap = 0,
        m0,
        m1,
        m3,
        p0,
        p2,
        p5,
        pl1,
        w = "100%",
        overflow,
        fd,
        mt2,
        mt4,
        mt8,
        mtb1,
        mtb2
    } = props;
    if (fs) justifyContent = "flex-start";
    if (fe) justifyContent = "flex-end";
    let margin;
    let padding = 1;
    if (m0) margin = 0;
    if (m1) margin = 8;
    if (m3) margin = 24;
    if (p0) padding = 0;
    if (p2) padding = 2;
    if (p5) padding = 5;

    let paddingLeft = 1;
    if (pl1) paddingLeft = 8;

    let marginTop = 1;
    if (mt2) marginTop = 2;
    if (mt4) marginTop = 4;
    if (mt8) marginTop = 8;

    let marginBottom = 1;
    if (mtb1) {
        marginTop = 8;
        marginBottom = 8;
    }

    if (mtb2) {
        marginTop = 8 * 2;
        marginBottom = 8 * 2;
    }

    let whiteSpace = "nowrap";
    if (overflow) whiteSpace = "normal";

    return (
        <Grid
            item
            xs={xs}
            md={md}
            style={{
                paddingTop: padding,
                paddingLeft: padding,
                marginTop: marginTop,
                marginBottom: marginBottom,
                width: w ? w : "auto",
                whiteSpace: whiteSpace
            }}
        >
            <Box
                display="flex"
                flexDirection={fd}
                justifyContent={justifyContent ? justifyContent : "center"}
                alignItems="center"
                gap={gap}
                style={{
                    margin: margin,
                    paddingLeft: paddingLeft
                }}
            >
                {props.children}
            </Box>
        </Grid>
    );
};

export const GridItem = (props) => {
    const { fe, fs, w = "100%", fs10, fs12, fs14, fs20, bold, blueColor, mt2, mt4, pl1 } = props;

    let color;
    if (blueColor) color = blue[700];
    return (
        <GridFlexBox fe={fe} fs={fs} w={w} mt2={mt2} mt4={mt4} pl1={pl1}>
            <Name fs10={fs10} fs12={fs12} fs14={fs14} fs20={fs20} bold={bold} color={color}>
                {props.children}
            </Name>
        </GridFlexBox>
    );
};

export const Title = (props) => {
    return <Typography variant="h5">{props.children}</Typography>;
};

export const Name = (props) => {
    const {
        bold,
        ct,
        fs10,
        fs12,
        fs13,
        fs14,
        fs20,
        color,
        wrap,
        mr = 0,
        ml1,
        ml3,
        ml5,
        cp,
        onClick = () => {}
    } = props;

    // Font Size
    let fontSize;
    if (ct) fontSize = 12;
    if (fs10) fontSize = 10;
    if (fs12) fontSize = 12;
    if (fs13) fontSize = 13;
    if (fs14) fontSize = 14;
    if (fs20) fontSize = 20;

    let cursor = "default";
    if (cp) cursor = "pointer";

    let fontWeight;
    if (bold) fontWeight = "bold";

    let noWrap = true;
    if (wrap) noWrap = false;

    let marginLeft = 0;
    if (ml1) marginLeft = 1;
    if (ml3) marginLeft = 3;
    if (ml5) marginLeft = 5;

    return (
        <Typography
            variant="body2"
            noWrap={noWrap}
            marginRight={mr}
            marginLeft={marginLeft}
            fontWeight={fontWeight}
            onClick={onClick}
            style={{ fontSize: fontSize, color: color, alignSelf: "center", cursor: cursor }}
        >
            {props.children}
        </Typography>
    );
};

export const GridDivider = (props) => {
    const { ptb = 10, plr = 2 } = props;

    return (
        <Grid
            item
            xs={12}
            style={{
                paddingTop: ptb,
                paddingLeft: plr,
                paddingRight: plr,
                paddingBottom: ptb
            }}
        >
            <Divider />
        </Grid>
    );
};

export const IconC = (props) => {
    let { color, onClick, cp, style, display, big, fs10, fs12, fs14, fs16, fs30 } = props;
    let cursor;
    let fontSize = "small";
    if (big) fontSize = "big";

    let fs;
    if (fs10) fs = 10;
    if (fs12) fs = 12;
    if (fs14) fs = 14;
    if (fs16) fs = 16;
    if (fs30) fs = 30;
    if (cp) cursor = "pointer";
    if (!style) style = { color: color, cursor: cursor, display: display, fontSize: fs };
    return (
        <Icon fontSize={fontSize} color={"primary"} style={style} onClick={onClick}>
            {props.children ? props.children : "account_circle"}
        </Icon>
    );
};

export const IconB = (props) => {
    const { icon, onClick = () => {} } = props;
    return (
        <IconButton onClick={() => onClick(props.children)}>
            <Icon>{icon ? icon : "account_circle"}</Icon>
            <Name fs10 ml1>
                {props.children}
            </Name>
        </IconButton>
    );
};

export const Loading = ({ loading }) => {
    return (
        <Grid item xs={12}>
            {loading && <LinearProgress />}
        </Grid>
    );
};

export const TextFieldC = ({
    onChange = () => {},
    value,
    name,
    id,
    label,
    small,
    date,
    shrinkLabel,
    password,
    passwordAF, //password autofill
    email,
    type = "text",
    fullWidth = true,
    required = true,
    autoFocus = false,
    labelColor,
    inputProps,
    w = "100%"
}) => {
    let size = "medium";
    if (small) size = "small";
    if (date) type = "date";
    if (passwordAF) type = "password"; // if use type = "password" chrome will autofill the password

    let style = {};
    if (password) {
        style = { WebkitTextSecurity: "disc" };
    }

    if (email) type = "email";
    return (
        <GridFlexBox mtb1 w={w}>
            <TextField
                size={size}
                autoFocus={autoFocus}
                name={name}
                id={id || name}
                type={type}
                value={value}
                label={label}
                required={required}
                fullWidth={fullWidth}
                onChange={onChange}
                InputLabelProps={{
                    shrink: date || shrinkLabel,
                    style: { WebkitTextSecurity: "none", color: labelColor }
                }}
                style={style}
                InputProps={inputProps}
            />
        </GridFlexBox>
    );
};

// TextField with Input Adornment
export const TextFieldIA = (props) => {
    const {
        name,
        id,
        required = true,
        size,
        fullWidth = true,
        label,
        type = "text",
        value,
        handleClick,
        icon = "face"
    } = props;
    return (
        <TextField
            name={name}
            id={id || name}
            required={required}
            size={size || "small"}
            fullWidth={fullWidth}
            label={label}
            type={type}
            value={value}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" onClick={handleClick}>
                        <Icon
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            {icon}
                        </Icon>
                    </InputAdornment>
                ),
                onKeyDown: (event) => {
                    event.preventDefault();
                }
            }}
        />
    );
};

export const ButtonC = (props) => {
    const { bgColor, small, p0, fs10, disabled = false, color = "primary", fullWidth, submit, mtb1, sx } = props;

    let padding;
    if (p0) padding = 0;
    let size;
    if (small) size = "small";

    let fontSize;
    if (fs10) fontSize = 10;

    let type = "button";
    if (submit) type = "submit";

    let marginTop = 1;
    let marginBottom = 1;
    if (mtb1) {
        marginTop = 8;
        marginBottom = 8;
    }

    return (
        <Button
            type={type}
            fullWidth={fullWidth}
            color={color}
            size={size}
            variant="contained"
            onClick={props.onClick}
            disabled={disabled}
            style={{
                marginTop: marginTop,
                marginBottom: marginBottom,
                backgroundColor: bgColor,
                paddingTop: padding,
                paddingBottom: padding,
                fontSize: fontSize
            }}
            sx={sx}
        >
            {props.children}
        </Button>
    );
};

export const ChartC = (props) => {
    return (
        <Typography
            noWrap
            fontSize={"small"}
            style={{
                marginLeft: -2,
                color: blue[500],
                fontSize: "10px"
            }}
        >
            {props.children}
        </Typography>
    );
};

export const Status = (props) => {
    let { pending, cp, onClick = () => {}, fs12, fs14, greyed } = props;

    let fontSize = "10px";
    if (fs12) fontSize = "12px";
    if (fs14) fontSize = "14px";

    let backgroundColor = blue[500];
    if (pending) backgroundColor = orange[500];
    if (greyed) backgroundColor = grey[500];

    let cursor;
    if (cp) cursor = "pointer";
    return (
        <Typography
            border="solid 1px"
            borderRadius="7px"
            fontSize={fontSize}
            textAlign="right"
            marginLeft="5px"
            marginTop="3px"
            paddingLeft="5px"
            paddingRight="5px"
            color="whitesmoke"
            backgroundColor={backgroundColor}
            style={{ cursor: cursor }}
            onClick={onClick}
        >
            {props.children}
        </Typography>
    );
};
