import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function loadPVs(user) {
    try {
        const dataRef = collection(db, "paymentVouchers");
        let dataSnap = [];
        if (user.role === "Admin" || user.role === "Super Admin") {
            const q = query(dataRef, orderBy("date"));
            dataSnap = await getDocs(q);
        } else {
            const q = query(dataRef, where("involve", "array-contains", user.id), orderBy("date"));
            dataSnap = await getDocs(q);
        }
        if (!dataSnap.empty) {
            const data = dataSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            return data;
        } else {
            return [];
        }
    } catch (error) {
        console.log(error);
    }
}
