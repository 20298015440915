import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function loadSalesRT(user, setSales, setLoading) {
    try {
        const salesRef = collection(db, "sales");

        setLoading(true);

        if (user.role === "Super Admin" || user.role === "Admin" || user.role === "Manager") {
            const q = query(salesRef, orderBy("date", "desc"));
            const unsubscribe = onSnapshot(q, (salesSnap) => {
                const sales = mapSnapshot(salesSnap);
                setSales(sales);
                console.log("Sales changed. Update...");
                setLoading(false);
            });
            return unsubscribe;
        } else {
            const q = query(salesRef, where("involve", "array-contains", user.id), orderBy("date", "desc"));
            const unsubscribe = onSnapshot(q, (salesSnap) => {
                const sales = mapSnapshot(salesSnap);
                setSales(sales);
                console.log("Sales changed. Update...");
                setLoading(false);
            });
            return unsubscribe;
        }
    } catch (err) {
        console.log(err);
        notification("Error", err.message, "danger");
    }
}
