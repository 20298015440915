import { Icon, Typography, Button } from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useContext } from "react";
import { GridFlexBox, Name, Status } from "../themes/themes";
import { useNavigate } from "react-router-dom";
import isAdmin from "../utils-functions/isAdmin";
import { AuthContext } from "../context/AuthContext";

export default function SaleMembersList({ member }) {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    const handleClick = (pvId) => {
        navigate("/payment-voucher-page/" + pvId);
    };

    return (
        <React.Fragment>
            <GridFlexBox fs w="67%">
                <Icon
                    fontSize="small"
                    style={{
                        color: blue[500]
                    }}
                >
                    account_circle
                </Icon>
                <Typography
                    variant="body2"
                    style={{
                        paddingLeft: "2px"
                    }}
                >
                    {`${member.displayName} | ${member.role}`}
                </Typography>
            </GridFlexBox>
            <GridFlexBox w="18%" fs>
                {isAdmin(user) && <Name>({member.percentage}%)</Name>}
            </GridFlexBox>
            <GridFlexBox fe w="15%" m0>
                {member.pvId
                    ? displayPV(user, member) && (
                          <PaymentVoucherStatus pvId={member.pvId} handleClick={handleClick} name={"PV"} cp />
                      )
                    : displayPV(user, member) && <PaymentVoucherStatus pending name={"pending"} />}
            </GridFlexBox>
        </React.Fragment>
    );
}

function PaymentVoucherStatus(props) {
    const { pending, cp, handleClick = () => {} } = props;
    return (
        <Status cp={cp} onClick={() => handleClick(props.pvId)} pending={pending}>
            {props.name}
        </Status>
    );
}

const displayPV = (user, member) => {
    if (isAdmin(user)) return true;
    if (user.id === member.id) return true;
    return false;
};
