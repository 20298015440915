import { Button, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = forwardRef((props, ref) => {
    const navigate = useNavigate();

    return (
        <Box m={1} ref={ref}>
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} textAlign="center">
                    <Typography variant="h5">Welcome to Simply Nice</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent={"center"}>
                        <Typography variant="body2">
                            Already have an Account
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} justifyContent="center">
                    <Box display={"flex"} justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => navigate("/sign-in")}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent={"center"}>
                        <Typography variant="body2">
                            Do not have an Account yet
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} justifyContent="center">
                    <Box display={"flex"} justifyContent="center">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate("/sign-up")}
                        >
                            Sign Up
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </Box>
    );
});

LandingPage.displayName = "LandingPage";

export default LandingPage;
