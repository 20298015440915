import React, { useEffect, useState } from "react";
import { GridContainer, GridFlexBox } from "../themes/themes";
import useWindowSize from "../hooks/useWindowSize";

export default function GalleryComponent({ imagesURL }) {
    const windowSize = useWindowSize();

    const [width, setWidth] = useState("100%");

    useEffect(() => {
        if (windowSize.width > 1080) {
            setWidth("50%");
        } else {
            setWidth("100%");
        }
    }, [windowSize]);

    return (
        <GridContainer>
            {imagesURL &&
                imagesURL.map((url) => (
                    <React.Fragment key={url}>
                        <GridFlexBox w={width}>
                            <img src={url} width={"100%"} />
                        </GridFlexBox>
                    </React.Fragment>
                ))}
        </GridContainer>
    );
}
