import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { GridContainer } from "../themes/themes";
import { GridDivider, GridFlexBox } from "../themes/themes";
import HomepageLinks from "../components/HomepageLinks";

export default function Homepage() {
    const { user } = useContext(AuthContext);

    return (
        <Box m={1}>
            <GridContainer>
                <GridFlexBox>
                    <Typography variant="h5">{user ? `Welcome ${user.displayName}` : "Homepage"}</Typography>
                </GridFlexBox>
                <GridDivider />
                <HomepageLinks />
            </GridContainer>
        </Box>
    );
}
