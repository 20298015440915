import GlobalList from "../components/GlobalList";
import React, { useState } from "react";
import { useRef } from "react";

export const ListContext = React.createContext();

export const ListProvider = ({ children }) => {
    const [title, setTitle] = useState();
    const [open, setOpen] = useState(false);
    const [objects, setObjects] = useState([]);
    const [displayName, setDisplayName] = useState();
    const [icon, setIcon] = useState();
    const [addAccount, setAddAccount] = useState(true);
    const [search, setSearch] = useState(false);

    const handleSave = (object) => {
        setOpen(false);
        awaitingPromiseRef.current.resolve(object);
    };

    const awaitingPromiseRef = useRef();

    // const {openGlobalList} = useContext(ListContext);
    // const response = await openGlobalList(objects, title, displayName, icon, addAccount, search)
    const openGlobalList = async (objects, title, displayName, icon, addAccount = false, search = false) => {
        setObjects(objects);
        setTitle(title);
        setDisplayName(displayName);
        setIcon(icon);
        setOpen(true);
        setAddAccount(addAccount);
        setSearch(search);

        return new Promise((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    return (
        <ListContext.Provider value={{ openGlobalList }}>
            {children}
            <GlobalList
                open={open}
                setOpen={setOpen}
                title={title}
                onSave={handleSave}
                displayName={displayName}
                objects={objects}
                onClose={handleSave}
                icon={icon}
                addAccount={addAccount}
                search={search}
            />
        </ListContext.Provider>
    );
};

const useList = () => {
    const { openGlobalList } = React.useContext(ListContext);

    // const response = await list(objects, title, displayName, icon, addAccount, search)
    return openGlobalList;
};

export default useList;
