import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";

export default function loadSaleRT(saleId, user, setSale, setLoading) {
    try {
        const dataRef = doc(db, "sales", saleId);
        if (user.role === "Super Admin" || user.role === "Admin") {
            const unsubscribe = onSnapshot(dataRef, (saleSnap) => {
                const sale = mapDocSnapshot(saleSnap);
                setSale(sale);
                setLoading(false);
            });
            return unsubscribe;
        } else {
            const unsubscribe = onSnapshot(dataRef, (saleSnap) => {
                const sale = mapDocSnapshot(saleSnap);
                if (sale && sale.involve.includes(user.email)) {
                    setSale(sale);
                    setLoading(false);
                } else {
                    console.log("User has no permission to view this document");
                }
            });
            return unsubscribe;
        }
    } catch (error) {
        console.log(error);
    }
}
