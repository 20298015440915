import { Container } from "@mui/system";
import { GridDivider, GridFlexBox, IconC, Loading, Name, Title } from "../themes/themes";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { UsersContext } from "../context/UsersContext";
import { useNavigate } from "react-router-dom";
import removeSpace from "../utils-functions/removeSpace";
import loadUsersRT from "../functions/loadUsersRT";

export default function UsersPage() {
    const { user } = useContext(AuthContext);
    const { users, setUsers, setUnsubscribeUsers } = useContext(UsersContext);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!users) {
            loadUsersRT(user, setUsers, setUnsubscribeUsers, setLoading);
        }
    }, []);

    const handleClick = (user) => {
        console.log(user);
        navigate("/user-card-page/" + user.id);
    };

    let usersToDisplay = users;
    if (usersToDisplay) {
        usersToDisplay.sort((a, b) => removeSpace(a.displayName).localeCompare(removeSpace(b.displayName)));
    }

    return (
        <Container maxWidth="sm">
            <GridFlexBox>
                <Title>Users Page</Title>
            </GridFlexBox>
            <Loading loading={loading} />
            <GridDivider />
            {usersToDisplay &&
                usersToDisplay.map((user) => (
                    <React.Fragment key={user.id}>
                        <GridFlexBox fs gap={1}>
                            <IconC onClick={() => handleClick(user)}>face</IconC>
                            <Name>{user.displayName}</Name>
                        </GridFlexBox>
                        <GridDivider />
                    </React.Fragment>
                ))}
        </Container>
    );
}
