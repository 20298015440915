import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { signOut } from "../functions/signOut";
import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { SalesContext } from "../context/SalesContext";
import { StateContext } from "../context/StateContext";
import { UsersContext } from "../context/UsersContext";
import { PaymentVouchersContext } from "../context/PaymentVouchersContext";

export default function SignOut() {
    const { setUser, setAuthUser } = useContext(AuthContext);
    const { setUsers, unsubscribeUsers } = useContext(UsersContext);
    const { setState } = useContext(StateContext);
    const { setSales, unsubscribe } = useContext(SalesContext);
    const { setPaymentVouchers } = useContext(PaymentVouchersContext);
    const navigate = useNavigate();

    useEffect(() => {
        exit();
    }, []);

    const exit = async () => {
        unsubscribe(); //unsubscribe to sales listener
        setSales();
        unsubscribeUsers();
        setUsers();
        setPaymentVouchers();
        setState();
        await signOut();
        setAuthUser();
        setUser();
        navigate("/");
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Button onClick={exit} variant="contained" color="error">
                    Click here to Sign Out
                </Button>
            </Box>
        </Container>
    );
}
