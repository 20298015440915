import { useEffect, useRef, useState } from "react";
import formatNumberWithSeparator from "../../utils-functions/formatNumberWithSeparator";
import { TextField } from "@mui/material";
import { Name } from "../../themes/themes";
import formatNumber from "../../utils-functions/formatNumber";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

const Cell = ({ part, item, id, type, currentEdit, setCurrentEdit, currentQuotation }) => {
    const [value, setValue] = useState("");

    useEffect(() => {
        if (type === "number") {
            let displayValue = formatNumberWithSeparator(item[id]);
            setValue(displayValue);
        } else {
            setValue(item[id]);
        }
    }, []);

    const handleBlur = async (e) => {
        if (e.type === "keydown" && e.key !== "Enter") {
            return;
        }

        const newItem = { ...item };
        if (type === "number") {
            let parsedNumber = parseFloat(value.replace(/,/g, ""));
            newItem[id] = parsedNumber;
        } else {
            newItem[id] = value;
        }
        const docRef = doc(db, "quotations", currentQuotation.id, "items", item.id);
        await setDoc(docRef, newItem, { merge: true });
        setCurrentEdit(null);
    };

    const handleChange = (e) => {
        let { value } = e.target;
        if (type === "number") {
            let displayValue = formatNumberWithSeparator(value);
            setValue(displayValue);
        } else {
            setValue(value);
        }
    };

    const checkEdit = (part, item, id) => {
        if (!currentEdit) return false;
        if (currentEdit.part === part && currentEdit.item === item && currentEdit.id === id) return true;
        return false;
    };

    return (
        <>
            {!checkEdit(part, item, id) ? (
                <Name cp onClick={() => setCurrentEdit({ part, item, id })}>
                    {typeof item[id] === "number" ? formatNumber(item[id]) : item[id] || "-"}
                </Name>
            ) : (
                <TextField
                    autoFocus
                    size="small"
                    fullWidth
                    onBlur={handleBlur}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleBlur}
                />
            )}
        </>
    );
};

export default Cell;
