import { Button, Grid, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import fillUpInvolve from "../test-functions/fillUpInvolve";
import { ButtonC, GridFlexBox } from "../themes/themes";
import mapSnapshot from "../utils-functions/mapSnapshot";

const MyButton = styled(Button)({
    margin: 5
});

const handleClick = async () => {
    const collectionRef = collection(db, "users");
    const snapshot = await getDocs(collectionRef);
    const users = mapSnapshot(snapshot);
    users.forEach(async (user) => {
        const docRef = doc(db, "users", user.id);
        await updateDoc(docRef, { companyId: "simply_nice" });
        console.log("updated");
    });
};

export default function TestPage() {
    return (
        <Box m={1}>
            <Grid container>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent={"center"}>
                        <Typography variant="h5">Test Page</Typography>
                    </Box>
                    <Box>
                        <MyButton variant="contained" onClick={() => fillUpInvolve()}>
                            Fill up Involve
                        </MyButton>
                    </Box>
                    <GridFlexBox>
                        <ButtonC onClick={handleClick}>Click me</ButtonC>
                    </GridFlexBox>
                    <GridFlexBox>
                        <ButtonC onClick={handleClick}>Fill Company Ids</ButtonC>
                    </GridFlexBox>
                </Grid>
            </Grid>
        </Box>
    );
}
