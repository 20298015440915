import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonC, Name } from "../themes/themes";
import { orange, red } from "@mui/material/colors";

export default function SelectComponent({
    open = false,
    list,
    handleClick,
    unselect,
    selectAll
}) {
    return (
        <Grid
            container
            display={open ? "flex" : "none"}
            position={"fixed"}
            bottom={5}
            width={"96%"}
            height="150px"
            border={"solid 1px"}
            style={{ backgroundColor: "white" }}
            overflow={"auto"}
        >
            <Grid item width={"80%"}>
                <Box m={1} display={"flex"} flexWrap={"wrap"}>
                    {list.map((item, i) => (
                        <Name mr={1} key={i}>
                            {item}
                        </Name>
                    ))}
                </Box>
            </Grid>
            <Grid item width={"20%"}>
                <Box m={1} justifyContent={"flex-end"} display={"flex"}>
                    <ButtonC small onClick={handleClick}>
                        Click
                    </ButtonC>
                </Box>
                <Box m={1} justifyContent={"flex-end"} display={"flex"}>
                    <ButtonC bgColor={red[500]} fs10 small onClick={unselect}>
                        Unselect
                    </ButtonC>
                </Box>
                <Box m={1} justifyContent={"flex-end"} display={"flex"}>
                    <ButtonC
                        bgColor={orange[500]}
                        fs10
                        small
                        onClick={selectAll}
                    >
                        SelectAll
                    </ButtonC>
                </Box>
            </Grid>
        </Grid>
    );
}
