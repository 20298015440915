import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { ReactNotifications } from "react-notifications-component";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ConfirmationProvider } from "./context-utils/ConfirmationContext";
import { InputProvider } from "./context-utils/InputContext";
import { ListProvider } from "./context-utils/ListContext";
import { AuthProvider } from "./context/AuthContext";
import { PaymentVouchersProvider } from "./context/PaymentVouchersContext";
import { SalesProvider } from "./context/SalesContext";
import { StateProvider } from "./context/StateContext";
import { UsersProvider } from "./context/UsersContext";
import "./index.css";
import { Toaster } from "react-hot-toast";

const host = window.location.hostname;
if (host !== "localhost") {
    Sentry.init({
        dsn: "https://444351fdded7430dbad7e5f23ba245e3@o4505539820257280.ingest.sentry.io/4505539821830144",
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <ReactNotifications />
        <BrowserRouter>
            <ConfirmationProvider>
                <InputProvider>
                    <ListProvider>
                        <StateProvider>
                            <UsersProvider>
                                <SalesProvider>
                                    <PaymentVouchersProvider>
                                        <AuthProvider>
                                            <Toaster />
                                            <App />
                                        </AuthProvider>
                                    </PaymentVouchersProvider>
                                </SalesProvider>
                            </UsersProvider>
                        </StateProvider>
                    </ListProvider>
                </InputProvider>
            </ConfirmationProvider>
        </BrowserRouter>
    </React.Fragment>
);
