import { InputContext, useInput } from "../context-utils/InputContext";
import { GridDivider, GridFlexBox, IconB } from "../themes/themes";
import React, { useContext, useState } from "react";
import FullDialog from "./FullDialog";
import UploadDocument from "./UploadDocument";
import useList from "../context-utils/ListContext";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { AuthContext } from "../context/AuthContext";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function ActionPanel({ handleAddAction, handleAddFile, title }) {
    const { openInputDialog } = useContext(InputContext);
    const { user } = useContext(AuthContext);

    const [open, setOpen] = useState(false);

    const handleClick = async (action) => {
        switch (action) {
            case "attach":
                setOpen(true);
                break;
            case "comment":
                {
                    const response = await openInputDialog("Add Action", "Add a new comment", "Comment");
                    if (response) {
                        handleAddAction(response);
                    }
                }
                break;
            default:
        }
    };

    const list = useList();
    const input = useInput();

    const handleFile = async (files) => {
        const collectionRef = collection(db, "companies", user.companyId, "tags");
        const snapshot = await getDocs(collectionRef);
        const tags = mapSnapshot(snapshot);
        const response = await list(tags, "Select Tag", "tag", "local_offer", true, true);
        if (response === "addAccount") {
            const tag = await input("Add new tag", "What is your tag's name?", "tag", "");
            if (tag) {
                await addDoc(collectionRef, { tag: tag, date: new Date(), createdBy: user.id });
                handleAddFile(files, tag);
            }
        } else {
            handleAddFile(files, response.tag || "file");
        }
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <GridFlexBox fe>
                <IconB cp icon="attach_file" onClick={handleClick}>
                    attach
                </IconB>
                <IconB cp icon="comment" onClick={handleClick}>
                    comment
                </IconB>
            </GridFlexBox>
            <FullDialog
                title={title}
                open={open}
                handleClose={handleClose}
                Component={<UploadDocument handleFile={handleFile} />}
            />
            <GridDivider />
        </React.Fragment>
    );
}
