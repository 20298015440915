export default function calculateProfit(user, sales) {
    if (user === "company") {
        let totalSales = 0;
        let totalProfit = 0;
        sales.forEach((sale) => {
            let memberPercentage = 0;
            totalSales += parseFloat(sale.saleAmount);
            sale.members.forEach((member) => {
                memberPercentage += parseFloat(member.percentage);
            });
            const companyPercentage = 100 - memberPercentage;
            const companyProfit = (companyPercentage * parseFloat(sale.profit)) / 100;
            totalProfit += companyProfit;
        });
        return { totalSales, totalProfit };
    }
}
