import { Button, Icon } from "@mui/material";
import React from "react";
import { useRef } from "react";
import { GridFlexBox } from "../themes/themes";

export default function SelectFiles({ handleClickSelect, startUpload }) {
    const inputRef = useRef(null);

    const handleChange = (e) => {
        const files = e.target.files;
        handleClickSelect(files);
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    return (
        <React.Fragment>
            <input type="file" onChange={handleChange} ref={inputRef} style={{ display: "none" }} multiple />
            <GridFlexBox>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<Icon>add</Icon>}
                    onClick={handleClick}
                    style={{ marginTop: "5px" }}
                    disabled={startUpload}
                >
                    Select Files
                </Button>
            </GridFlexBox>
        </React.Fragment>
    );
}
