import { storage } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export async function uploadFile(file, fileRef, setProgress) {
    if (!file) {
        return null;
    }
    const storageRef = ref(storage, fileRef);

    let reference = { resolve: () => null, reject: () => null };
    let downloadUrlPromise = new Promise((resolve, reject) => {
        reference = { resolve, reject };
    });

    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
        "state_changed",
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
        },
        (error) => {
            console.log(error);
            notification("Opps", error.message, "danger");
        },
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setProgress(100);
                reference.resolve(downloadURL);
            });
        }
    );
    return await downloadUrlPromise;
}
