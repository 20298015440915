import { doc, runTransaction } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function deletePVs(list, paymentVouchers, setPaymentVouchers) {
    try {
        let newPVs = [...paymentVouchers];
        await Promise.all(
            list.map(async (pv) => {
                await Promise.all(
                    pv.sales.map(async (sale) => {
                        const saleRef = doc(db, "sales", sale.id);
                        const pvRef = doc(db, "paymentVouchers", pv.id);
                        await runTransaction(db, async (transaction) => {
                            const saleSnap = await transaction.get(saleRef);
                            if (saleSnap.exists()) {
                                let sale = { ...saleSnap.data() };
                                const index = sale.members.findIndex((m) => m.id === pv.email);
                                if (index !== -1) {
                                    if (sale.members[index].pvId === pv.id) {
                                        sale.members[index].pvId = "";
                                        transaction.update(saleRef, sale);
                                    }
                                }
                            }
                            transaction.delete(pvRef);
                        });
                        console.log("Sale updated: ", sale.id);
                        return Promise.resolve();
                    })
                );
                const index = newPVs.findIndex((doc) => doc.id === pv.id);
                newPVs.splice(index, 1);
                console.log("PV deleted: ", pv.id);
                return Promise.resolve();
            })
        );
        setPaymentVouchers(newPVs);
        notification("Success", "Payment Vouchers Deleted", "success");
    } catch (error) {
        console.log(error);
    }
}
