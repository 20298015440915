import { Container } from "@mui/system";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, Loading, Title } from "../themes/themes";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { useEffect } from "react";
import loadPV from "../functions/loadPV";
import { useState } from "react";
import PaymentVoucherComponent from "../components/PaymentVoucherComponent";

export default function PaymentVoucherPage() {
    const navigate = useNavigate();

    const { pvId } = useParams();

    const [voucher, setVoucher] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getData() {
            setLoading(true);
            const voucher = await loadPV(pvId);
            setVoucher(voucher);
            console.log(voucher);
            setLoading(false);
        }

        getData();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Container maxWidth="md">
            <GridContainer>
                <GridFlexBox>
                    <Title>Payment Voucher</Title>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridDivider />
                <PaymentVoucherComponent voucher={voucher} />
                <GridFlexBox fs>
                    <ButtonC small onClick={handleBack}>
                        Back
                    </ButtonC>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
