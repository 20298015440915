import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

//Main Database
const firebaseConfig = {
    apiKey: "AIzaSyChjkGkd4pmKNQue5KktvssAT_DoeacLsg",
    authDomain: "simply-nice-app.firebaseapp.com",
    databaseURL: "https://simply-nice-app.firebaseio.com",
    projectId: "simply-nice-app",
    storageBucket: "simply-nice-app.appspot.com",
    messagingSenderId: "876174264121",
    appId: "1:876174264121:web:657603a0a9aa6882170ae8",
    measurementId: "G-Y0H8MNXCPD"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export default app;
