import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/firebase-utils";
import loadUser from "./loadUser";
import { notification } from "../utils-functions/notification";

export async function signIn(state, setUser, setAuthUser) {
    try {
        const { user } = await signInWithEmailAndPassword(
            auth,
            state.email.toLowerCase(),
            state.password
        );
        const userInFs = await loadUser(user.email);
        if (userInFs) {
            setUser(userInFs);
            setAuthUser(user);
            localStorage.setItem("user", JSON.stringify(userInFs));
            localStorage.setItem("authUser", JSON.stringify(user));
            console.log(user.email);
            notification("Signed in", "Signed in successfully", "success");
            return userInFs;
        } else {
            return null;
        }
    } catch (err) {
        let message = err.message;

        if (err.code === "auth/user-not-found") {
            message = "Please check if you have entered the correct email";
        }

        if (err.code === "auth/wrong-password") {
            message = "Please check if you have entered the correct password";
        }

        notification("Opps", message, "danger");
        console.log(err);
    }
}
