import { Grid, useTheme } from "@mui/material";
import { ButtonC, GridFlexBox, Name } from "../themes/themes";
import { green, purple, red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import { tokens } from "../theme";

export default function SelectComponent2({ select, data, setSelect, handleDelete }) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if (arrayIsEmpty(select)) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [select]);

    const unselect = () => {
        setSelect([]);
    };

    const selectAll = () => {
        const newSelect = data.map((item) => item.id);
        setSelect(newSelect);
    };

    return (
        <Grid
            container
            display={open ? "block" : "none"}
            position={"fixed"}
            bottom={5}
            width={"96%"}
            height="80px"
            border={"solid 1px"}
            style={{ backgroundColor: colors.primary[900] }}
            overflow={"auto"}
        >
            <GridFlexBox gap={1} p5>
                <Name>{select.length}</Name>
                <Name>items selected</Name>
            </GridFlexBox>
            <GridFlexBox gap={1} p5>
                <ButtonC small fs10 bgColor={red[500]} onClick={() => handleDelete(select)}>
                    Delete
                </ButtonC>
                <ButtonC bgColor={purple[500]} fs10 small onClick={unselect}>
                    Unselect
                </ButtonC>
                <ButtonC bgColor={green[500]} fs10 small onClick={selectAll}>
                    SelectAll
                </ButtonC>
            </GridFlexBox>
        </Grid>
    );
}
