import React, { useState } from "react";
import { createContext } from "react";

export const SalesContext = createContext();

export const SalesProvider = ({ children }) => {
    const [sales, setSales] = useState();
    const [unsubscribe, setUnsubscribe] = useState(() => () => {});

    return (
        <SalesContext.Provider value={{ sales, setSales, unsubscribe, setUnsubscribe }}>
            {children}
        </SalesContext.Provider>
    );
};
